import { fetchCfgObjectIds } from "../../tools/helper";

import { useEffect, useState } from "react";
import { JSX } from "react";

import { Alert, Box, MenuItem, Select } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";

export function CFGObjectSelect(): JSX.Element {
  const [cfgObjects, setCfgObjects] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getCfgObjectIds(): Promise<void> {
      const ids = await fetchCfgObjectIds();
      setCfgObjects(ids);
    }

    getCfgObjectIds();
  }, []);

  return (
    <Alert severity="error">
      <Box marginBottom={"0.75rem"}>
        No cfgObjectId set. Please set an cfgObject or select the cfgObject you
        were looking for. When including this application in an iFrame, check
        the url of the iFrame for the correct cfgObjectId.
      </Box>
      <Select
        value=""
        sx={{ minWidth: "400px", marginBlock: "0.5rem" }}
        onChange={(event) => {
          navigate({
            from: "/configurator",
            search: (prev: object) => ({
              ...prev,
              cfgObjectId: event.target.value,
            }),
          });
        }}
      >
        {cfgObjects?.map((cfgObject) => {
          return (
            <MenuItem value={cfgObject} key={cfgObject}>
              {cfgObject}
            </MenuItem>
          );
        })}
      </Select>
    </Alert>
  );
}
