import { CustomSubconfigurationItemLabel } from "../CustomSubconfigurationItemLabel/CustomSubconfigurationItemLabel";

import { JSX, Suspense } from "react";

import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { SubconfigurationVariantNavigationProps } from "@plansoft-configuration-renderer/components";

/**
 * Tab control on configuration level
 * @category UI
 * @param props
 * @returns
 */
export function CustomSubconfigurationTabsNavigation(
  props: SubconfigurationVariantNavigationProps,
): JSX.Element {
  const {
    items,
    activePath,
    setActivePath: setActiveItem,
    navigationStyle,
  } = props;

  return (
    <Suspense>
      <Tabs
        className="custom-subconfiguration-tabs-navigation"
        orientation={props.orientation}
        onChange={(event, newValue) => setActiveItem(newValue)}
        value={activePath}
        sx={{
          marginLeft: "auto",
          marginRight: "24px",
          ...navigationStyle,
        }}
      >
        {items.map((item) => (
          <Tab
            className="custom-subconfiguration-tabs-navigation-tab"
            key={item.path}
            value={item.path}
            label={
              <CustomSubconfigurationItemLabel
                item={item}
                showCompleteIndicator={props.showCompletenessIndicator}
              />
            }
          />
        ))}
      </Tabs>
    </Suspense>
  );
}
