import { CustomPageHeading } from "../../../components/CustomPageHeading/CustomPageHeading";
import { BtnImportConfigId } from "../../../components/common/BtnImportConfigId/BtnImportConfigId";

import { JSX } from "react";
import { useTranslation } from "react-i18next";

import { VMItemGroups } from "@4pace-cpq/core";
import { CartItem } from "@4pace-cpq/core/dist/types/views/CartView/CartView";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "@tanstack/react-router";

export function CartViewHeading({
  item,
  itemGroupId,
}: {
  item: CartItem;
  itemGroupId: VMItemGroups["id"];
}): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { cartId: quoteId } = useParams({
    strict: false,
  });

  function routeToConfigurator(): void {
    navigate({
      // to: `/quotes/$quoteId/catalog/CustomProducts`,
      to: `/configurator?cfgObjectId=&productId=&quoteId=${quoteId}&itemGroupId=${itemGroupId}&lineItemId=`,
      /*params: {
        quoteId,
      },*/
    });
  }
  console.log("CartViewHeading item", item);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <CustomPageHeading
        text={t("WEG:header.cart.title")}
        //subtitle={item.name}
      />
      <Box
        display="flex"
        gap={2}
        sx={{ mt: "1rem", marginRight: "1rem", marginBottom: "rem" }}
      >
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={routeToConfigurator}
        >
          <Typography>{t("WEG:cartViewHeader.button.title")}</Typography>
        </Button>
        <BtnImportConfigId />
      </Box>
    </Box>
  );
}
