import { ConfigurationControls } from "../ConfigurationControls/ConfigurationControls";
import { CustomSubconfigurationNavigationComponents } from "../CustomSubconfigurationNavigationComponents/CustomSubconfigurationNavigationComponents";

import { JSX } from "react";

import Box from "@mui/material/Box";
import {
  SubconfigurationNavigationComponents,
  SubconfigurationNavigationContent,
  SubconfigurationNavigationControl,
  SubconfigurationNavigationOrientation,
  SubconfigurationNavigationProps,
  SubconfigurationNavigationSecondaryControl,
  useSubconfigurationNavigation,
  useSubconfigurationNavigationItems,
} from "@plansoft-configuration-renderer/components";
import { Gateway } from "@plansoft-configuration-renderer/gateway";

/**
 * Tab control on configuration level
 * @category UI
 * @param props
 * @returns
 */
export function CustomSubconfigurationNavigation(
  props: SubconfigurationNavigationProps,
): JSX.Element {
  const componentsMapper = props.custom?.renderAsIconNavigation
    ? CustomSubconfigurationNavigationComponents
    : SubconfigurationNavigationComponents;
  const {
    control = SubconfigurationNavigationControl.Stepper,
    orientation = SubconfigurationNavigationOrientation.Horizontal,
    secondaryControl = SubconfigurationNavigationSecondaryControl.Buttons,
    components = componentsMapper,
  } = props;

  const items = useSubconfigurationNavigationItems({});

  const {
    activePath,
    setActivePath,
    resetActivePath,
    next,
    nextDisabled,
    previous,
    previousDisabled,
  } = useSubconfigurationNavigation(items);

  const {
    NavigationComponents,
    AlignmentComponents,
    ContentComponents,
    SecondaryNavigationComponents,
  } = components;

  const Navigation = NavigationComponents[control];
  const SecondaryNavigation = SecondaryNavigationComponents[secondaryControl];
  const AlignmentComponent = AlignmentComponents[orientation];

  const activeItem = items.find((item) => item.path === activePath);

  return (
    <AlignmentComponent
      navigation={
        <Navigation
          orientation={orientation}
          activePath={activePath}
          setActivePath={setActivePath}
          items={items}
          navigationStyle={props.navigationStyle}
          showCompletenessIndicator={props.showCompletenessIndicator}
          stepperProps={props.stepperProps}
        />
      }
      content={
        activeItem ? (
          <>
            <ConfigurationControls />

            <Box sx={{ overflowY: "auto", height: "76vh", px: 0.5 }}>
              <SubconfigurationNavigationContent
                item={activeItem}
                components={ContentComponents}
              />
            </Box>
            <Gateway into="configurator-footer-left">
              <SecondaryNavigation
                onNextClicked={next}
                onPreviousClicked={previous}
                nextDisabled={nextDisabled}
                previousDisabled={previousDisabled}
                onResetClicked={resetActivePath}
                resetDisabled={false}
              />
            </Gateway>
          </>
        ) : null
      }
    />
  );
}
