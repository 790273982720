import { CustomPageHeading } from "../../../components/CustomPageHeading/CustomPageHeading";

import { JSX, Suspense } from "react";
import { useTranslation } from "react-i18next";

import { useQuotesServiceGetByIdQuotes } from "@4pace-cpq/core";
import { SubnavigationItems } from "@4pace-cpq/core/dist/types/views/QuoteDetailsView/QuoteDetailsView";
import { Skeleton } from "@mui/material";
import { useChildMatches, useParams } from "@tanstack/react-router";

export function QuoteDetailsViewPageHeader({
  subNavigationItems,
}: {
  subNavigationItems: SubnavigationItems;
}): JSX.Element {
  const { t } = useTranslation();
  const { quoteId: quoteIdParam } = useParams({
    strict: false,
  });

  const { data: quote } = useQuotesServiceGetByIdQuotes(
    { id: quoteIdParam },
    undefined,
    {
      enabled: !!quoteIdParam,
    },
  );

  const childMatches = useChildMatches();
  const routeId = childMatches[0].routeId;

  const activeNavigationItem = subNavigationItems.filter(
    (item) => item.to === routeId,
  );

  return (
    <Suspense
      fallback={
        <Skeleton
          variant="rectangular"
          sx={{
            marginTop: "1rem",
            width: "100%",
            height: "46.433px",
          }}
        />
      }
    >
      <CustomPageHeading
        text={t(`VB:header.${activeNavigationItem[0].id}.title`)}
        subtitle={quote?.name}
      />
    </Suspense>
  );
}

export default QuoteDetailsViewPageHeader;
