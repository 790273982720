import { CustomLineItem } from "../CustomLineItem/CustomLineItem";

import { JSX } from "react";

import { VMItemGroups, VMLineItems, VMQuotes } from "@4pace-cpq/core";
import { TableRow } from "@mui/material";

export function CustomLineItemAccordion({
  lineItem,
  quoteId,
  itemGroupId,
  idx,
}: {
  lineItem: VMLineItems;
  quoteId: VMQuotes["id"];
  itemGroupId: VMItemGroups["id"];
  idx: number;
}): JSX.Element {
  /*const hasSubItems = Boolean(
    lineItem.subItems && lineItem.subItems.length > 0,
  );*/
  //const [isExpanded, setIsExpanded] = useState(hasSubItems);
  return (
    <TableRow>
      <CustomLineItem
        item={lineItem}
        quoteId={quoteId}
        itemGroupId={itemGroupId}
        idx={idx}
      />
    </TableRow>
  );
}
