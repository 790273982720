//import ExpectedDelivery from "../../../components/ExpectedDelivery/ExpectedDelivery";
import { BtnAskForQuote } from "../../../components/common/BtnAskForQuote/BtnAskForQuote";
import { BtnJumpToEasy } from "../../../components/common/BtnJumpToEasy/BtnJumpToEasy";

import { JSX } from "react";

//import { VMQuotes } from "@4pace-cpq/core";
import { Box, Container } from "@mui/material";

export function CartViewFooter({
  //item,
  isLoggedIntoEasy,
}: {
  //item: VMQuotes;
  isLoggedIntoEasy: boolean;
}): JSX.Element {
  /*if (!item?.itemGroups?.[0]?.lineItems) {
    return <></>;
  }*/

  return (
    <Box mt="16px" sx={{ boxShadow: "0 -15px 15px 0 rgba(0,0,0,.125)" }}>
      <Container>
        <Box display="flex" justifyContent="flex-end" py={2} gap={2}>
          <BtnJumpToEasy isLoggedIntoEasy={isLoggedIntoEasy} />
          <BtnAskForQuote />
        </Box>
      </Container>
    </Box>
  );
}
