import { JSX } from "react";

import { PrintButtonArea } from "@4pace-print-button-area/core";
import { useSessionId } from "@plansoft-configuration-renderer/framework";
import { Gateway } from "@plansoft-configuration-renderer/gateway";
import { useQueryClient } from "@tanstack/react-query";

export const UtilsProductView = (): JSX.Element => {
  const queryClient = useQueryClient();
  const sessionId = useSessionId();
  return (
    <>
      <Gateway into="configurator-footer-right">
        <PrintButtonArea
          queryClient={queryClient}
          areaIdentifier="printDataSheet"
          locale="en-US"
          printRequestParams={{
            quoteId: sessionId,
          }}
          options={{
            docServerBasePath: "/preview",
            orchestratorBasePath: "/preview",
          }}
        />
      </Gateway>
    </>
  );
};
