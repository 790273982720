import { JSX } from "react";

import LockSharpIcon from "@mui/icons-material/LockSharp";
import { Box, Link, Typography } from "@mui/material";

export function AppHeaderUtilities({
  isLoggedIntoEasy,
}: {
  isLoggedIntoEasy: boolean;
}): JSX.Element {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" mr={1}>
      <Box display="flex" alignItems="center" gap={1}>
        {/* <Skeleton width="3.09em" />

        <Skeleton width="3.09em" />

        <Skeleton width="3.09em" /> */}
        {isLoggedIntoEasy ? (
          <Link href="/oauth2/sign_out">
            {/* TODO call this to check login status: /oauth2/auth */}
            <Typography
              variant="body2"
              display="flex"
              alignItems="center"
              sx={{ textTransform: "capitalize" }}
            >
              Logout
            </Typography>
          </Link>
        ) : (
          <Link href="/oauth2/start">
            <Typography
              variant="body2"
              display="flex"
              alignItems="center"
              sx={{ textTransform: "capitalize" }}
            >
              <LockSharpIcon fontSize="inherit" />
              Login
            </Typography>
          </Link>
        )}
      </Box>
      {/* <SearchBar /> */}
      {/* 
      <Skeleton width="16.18em" height="2em" /> */}
    </Box>
  );
}
