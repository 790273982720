export default {
  WEG: {
    "btn.askForQuote": "Angebot anfragen",
    "btn.import": "importieren",
    "btn.importConfigId": "Config ID importieren",
    "btn.loginToEasy": "Return to EASY",
    "btn.sendRequest": "Anfrage senden",
    "button.back": "Zurück",
    "buttons.addToQuote": "Zur Artikelliste hinzufügen",
    "buttons.backToCart": "Zur Projektliste",
    "buttons.backToRoot": "Zur Startseite",
    "buttons.resetConfiguration": "Reset",
    "buttons.saveAsTemplate": "Vorlage speichern",
    "buttons.SaveConfigurationButton": "Konfiguration Speichern",
    "buttons.showAllProjects": "Alle Projekte anzeigen",
    "cart.table.header.configurationId": "Config ID",
    "cart.table.header.customerId": "Kunden-ID/ TAG Nr",
    "cart.table.header.item": "Artikel",
    "cart.table.header.notes": "Notizen",
    "cart.table.header.quantity": "Menge",
    "cart.table.header.type": "Typ",
    "cart.table.header.syncSelect": "Sync",
    "cartViewHeader.button.title": "Neue Konfiguration",
    "dialog.addToQuote.title": "Bitte wählen Sie eine Artikelliste aus",
    "header.cart.title": "Projektliste",
    "label.configId": "Config ID",
    "label.form.email": "E-Mail",
    "label.form.name": "Vorname",
    "label.form.surname": "Nachname",
    "label.form.street": "Straße",
    "label.form.streetNr": "Nr.",
    "label.form.zipCode": "PLZ",
    "label.form.city": "Stadt/Ort",
    "label.form.phone": "Telefon",
    "label.form.formUserComment": "Kommentar",
    "label.form.formPurchasePlace": "Kaufort",
    "label.form.formRegion": "Region",
    "label.form.formCity": "Stadt",
    "error.noPOSfound": "Keine Servicepunkte gefunden.",
    "error.invalidGUID": "Ungültige GUID.",
    "latestProjects.table.header.batch": "Kommission",
    "latestProjects.table.header.lastChange": "Letzte Änderung",
    "latestProjects.table.header.orderNumber": "Bestellnummer",
    "latestProjects.table.header.projectName": "Projektname",
    "latestProjects.table.header.projectNumber": "Projektnummer",
    "latestProjects.table.title": "Aktuellste Projekte",
    "preselector.page1.link": "#",
    "preselector.page1.linkText": "Benötigen Sie Hilfe?",
    "preselector.page1.text":
      "Finden Sie ein Produkt, das Ihren Bedürfnissen entspricht.",
    "products.buttons.addToQuote": "Zur Artikelliste hinzufügen",
    "products.buttons.configure": "Konfigurieren",
    "title.configurator.appCalc": "Anwendungs-Rechner",
    "title.configurator.preselection": "Productfinder",
    "title.configurator.professional": "Professionell",
    "tooltip.copyToClipboard": "Kopieren",
    "config.btn.searchProduct": "Produkt Suchen",
  },
  CPQ: {
    "button.createQuote": "test", ////todo -[ am ]-  fix
  },
};
