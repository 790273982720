import { ReactNode, Suspense } from "react";
import { JSX } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import LinearProgress from "@mui/material/LinearProgress";
import { CadClickViewer3DScriptUrlContext } from "@plansoft-configuration-renderer/components";

interface MinimalAppContextProps {
  children: ReactNode;
}

export function MinimalAppContext(props: MinimalAppContextProps): JSX.Element {
  return (
    <>
      <CssBaseline />
      <CadClickViewer3DScriptUrlContext.Provider value="kim-viewer-3d/Viewer3D.1.6.2.min.js">
        <Suspense fallback={<LinearProgress />}>{props.children}</Suspense>
      </CadClickViewer3DScriptUrlContext.Provider>
    </>
  );
}
