import { appLanguage } from "../App";

import { Suspense } from "react";
import { JSX } from "react";

import { Box } from "@mui/material";
import {
  CommonErrorBoundary,
  DateLocalizationProvider,
  LocalizationProvider,
  OAuthManager,
} from "@plansoft-configuration-renderer/components";
import {
  GatewayDest,
  GatewayProvider,
} from "@plansoft-configuration-renderer/gateway";

import { CFGObjectSelect } from "./renderer/CFGObjectSelect";
import { MinimalAppContext } from "./renderer/MinimalAppContext";
import { MinimalRenderer } from "./renderer/MinimalRenderer";

const CONFIGURATOR_BASE_URL = "/preview";

export function Configurator({
  cfgObjectId,
  state,
  /* productSelection, */
}: {
  cfgObjectId?: string;
  state?: string;
  productSelection?: string;
}): JSX.Element {
  return (
    <MinimalAppContext>
      <CommonErrorBoundary>
        <Suspense>
          <LocalizationProvider
            configurationLocale={appLanguage}
            uiLocale={appLanguage}
            configuratorBasePath={CONFIGURATOR_BASE_URL}
          >
            <DateLocalizationProvider localeCode={appLanguage}>
              <GatewayProvider>
                <Box pb={8}>
                  <Box sx={{ position: "sticky", top: 0, maxHeight: "90vh" }}>
                    <GatewayDest name="navigation" />
                  </Box>
                  {cfgObjectId ? (
                    <MinimalRenderer
                      baseUrl={CONFIGURATOR_BASE_URL}
                      cfgObjectId={cfgObjectId}
                      /* productSelection={productSelection} */
                      language={appLanguage}
                      state={state}
                    />
                  ) : (
                    <CFGObjectSelect />
                  )}
                  <OAuthManager
                    basePath={CONFIGURATOR_BASE_URL}
                    isLocalDevelopment={import.meta.env.DEV}
                  />
                </Box>
              </GatewayProvider>
            </DateLocalizationProvider>
          </LocalizationProvider>
        </Suspense>
      </CommonErrorBoundary>
    </MinimalAppContext>
  );
}
