import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";

export const customPalette = {
  mode: "light",
  primary: {
    main: "#0091BD",
    dark: "#3572A6",
    contrastText: "#fff",
  },
  secondary: {
    main: "rgba(0, 0, 0, 0.6)",
    light: "#a0a0a0",
  },
  grey: {
    100: "#F5F5F5",
    300: "#D9DADA",
    500: "#767676",
    600: "#646464",
    800: "#44474A",
    900: "#333333",
  },
} satisfies PaletteOptions;

const customTheme = createTheme({
  palette: customPalette,
  colorSchemes: {
    dark: false,
  },
  typography: {
    h1: {
      fontSize: "2rem",
      fontWeight: 100,
      color: customPalette.primary.dark,
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 100,
      color: customPalette.primary.dark,
    },
    h3: {
      fontSize: "1.5rem",
    },
    h4: {
      fontSize: "1.25rem",
    },
    h5: {
      fontSize: "1.1rem",
    },
  },
  shape: {},
  components: {
    MuiCssBaseline: {
      //eslint-disable-next-line
      styleOverrides: (themeParam) => ({
        body: {
          minHeight: "100vh",
        },
        "#root": {
          minHeight: "100vh",
          ">div.MuiBox-root:nth-of-type(2)": {
            minHeight: "100vh",
            ">div.MuiContainer-root:nth-of-type(2)": {
              padding: 0,
              display: "flex",
              flexDirection: "column",
              minHeight: "100%",
            },
          },
        },
      }),
    },
    MuiButtonBase: {
      defaultProps: {
        // disableRipple: true,
      },
    },
    MuiCard: {
      //TODO cleanup, very hacky in here
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 0,
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            boxShadow: "0 0 0 1px #BDBFC4 inset",
            pointerEvents: "none",
          },
          "&:hover": {
            backgroundColor: "#fff",
            "&::before": {
              boxShadow: "0 0 0 3px " + customPalette.primary.main + " inset",
            },
          },

          ".MuiButtonBase-root > .MuiBox-root:last-of-type": {
            background: customPalette.primary.main,
            color: "#fff",
            ".MuiCardContent-root": {
              padding: ".6rem .6rem",
            },
          },
          "&.MuiPaper-elevation8": {
            "&::before": {
              boxShadow: "0 0 0 3px " + customPalette.primary.dark + " inset",
            },
            ".MuiButtonBase-root > .MuiBox-root:last-of-type": {
              background: customPalette.primary.dark,
            },
          },
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          objectFit: "cover",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: "none",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: ".9rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "8px",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: "1px solid " + customPalette.secondary.light,
          background: "#fff",
          "&::before": {
            top: 0,
            height: "100%",
            border: "none",
            borderColor: customPalette.primary.main,
          },

          "&:hover": {
            background: "#fff",
            "&::before": {
              border: "2px solid " + customPalette.primary.main + " !important",
            },
          },
        },
        input: {
          paddingTop: "8px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          "&.Mui-focused": {
            background: "#fff",
            "&::before": {
              border: "2px solid " + customPalette.primary.dark + " !important",
            },
          },
        },
        icon: {
          // Add style overrides for the icon (optional)
          color: customPalette.primary.main, // Example of color change
        },
      },
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
    },
  },
});

export const theme = responsiveFontSizes(customTheme);
