import { customPalette } from "../../theme/theme";
import { CustomLineItemAccordion } from "../CustomLineItemAccordion/CustomLineItemAccordion";

import { JSX } from "react";
import { useTranslation } from "react-i18next";

import { VMItemGroups, VMLineItems, VMQuotes } from "@4pace-cpq/core";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export function CustomLineItems({
  lineItems,
  quoteId,
  itemGroupId,
  customerId,
}: {
  lineItems?: VMLineItems[] | null;
  quoteId: VMQuotes["id"];
  customerId?: VMQuotes["customerId"];
  itemGroupId: VMItemGroups["id"];
}): 0 | JSX.Element | undefined {
  const { t } = useTranslation();
  return (
    lineItems?.length && (
      <TableContainer component={Paper} sx={{ p: 2, pt: 2, pb: 4, mt: 4 }}>
        <Box display="flex" gap={4} mb={2}>
          <Box display="flex" gap={1} alignItems="baseline">
            <Typography
              variant="body2"
              fontWeight="600"
              color={customPalette.primary.dark}
            >
              CartID
            </Typography>
            <Typography
              variant="body1"
              fontWeight="100"
              color={customPalette.primary.dark}
            >
              #{quoteId}
            </Typography>
          </Box>
          {customerId ? (
            <Box display="flex" gap={1}>
              <Typography
                variant="body2"
                fontWeight="600"
                color={customPalette.primary.dark}
              >
                Customer Number
              </Typography>
              <Typography
                variant="body1"
                fontWeight="100"
                color={customPalette.primary.dark}
              >
                #{quoteId}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography variant="caption">
                  {t("WEG:cart.table.header.syncSelect")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="caption">
                  {t("WEG:cart.table.header.item")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="caption">
                  {t("WEG:cart.table.header.configurationId")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="caption">
                  {t("WEG:cart.table.header.type")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="caption">
                  {t("WEG:cart.table.header.quantity")}
                </Typography>
              </TableCell>
              <TableCell align="left" colSpan={2}>
                <Typography variant="caption">
                  {t("WEG:cart.table.header.notes")}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="caption">
                  {t("WEG:cart.table.header.customerId")}
                </Typography>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems?.map((lineItem, idx) => {
              return (
                <CustomLineItemAccordion
                  key={lineItem.id}
                  lineItem={lineItem}
                  quoteId={quoteId}
                  itemGroupId={itemGroupId}
                  idx={idx}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}
