import { customPalette } from "../../theme/theme";

import { ReactNode } from "react";
import { JSX } from "react";

import Typography from "@mui/material/Typography";

export function Title({ text }: { text: ReactNode }): JSX.Element {
  return (
    <Typography
      variant="h1"
      sx={{
        textTransform: "none",
        fontWeight: "100",
        lineHeight: "1.375rem",
        letterSpacing: "0.46px",
        position: "relative",
        pl: ".25rem",
        "&::after": {
          content: '""',
          display: "block",
          width: "2em",
          borderColor: customPalette.primary.main,
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          position: "absolute",
          bottom: "-.5em",
          left: "0",
        },
      }}
    >
      {text}
    </Typography>
  );
}
