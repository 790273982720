import { JSX } from "react";

import { SxProps, TextField, Theme } from "@mui/material";

interface ITextFieldSimple {
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  sx?: SxProps<Theme>;
  multiline?: boolean;
  rows?: number;
  error?: boolean;
  helperText?: string;
}
export const TextFieldSimple = ({
  value,
  onChange,
  type,
  sx,
  multiline,
  rows,
  error,
  helperText,
}: ITextFieldSimple): JSX.Element => {
  const defaultSx: SxProps<Theme> = {};
  return (
    <TextField
      helperText={helperText}
      error={error}
      multiline={multiline}
      rows={rows}
      id="outlined-basic"
      variant="outlined"
      size="small"
      type={type || "text"}
      value={value}
      onChange={onChange}
      sx={{ ...defaultSx, ...sx }}
    />
  );
};
