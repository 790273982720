import { Fragment, useCallback, useEffect, useState } from "react";
import { JSX } from "react";

import {
  useConfigurationEvent,
  useSessionId,
} from "@plansoft-configuration-renderer/framework";

/**
 * Demo on how to access internal state
 * @returns
 */
export function ConfigurationStateSpy(): JSX.Element {
  const [initialized, setInitialized] = useState<boolean>(false);
  const sessionId = useSessionId();

  // log session id when it changes
  useEffect(() => {
    console.log("Configuration session id is", sessionId);
  }, [sessionId]);

  const onConfigurationEvent = useCallback(() => {
    console.log("-- CHANGE! --", initialized);
    setInitialized(true);
  }, [initialized]);

  useConfigurationEvent({
    onConfigurationEvent: onConfigurationEvent,
  });

  return <Fragment />;
}
