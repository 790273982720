export function prettyLogger(text: string, color: string = "blue"): void {
  const styles = [
    "color: white",
    `background-color: ${color}`,
    "font-size: 12px",
    "padding: 20px",
    "border-radius: 5px",
  ].join(";");

  console.log(`%c ${text}`, styles);
}
