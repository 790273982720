import { useImportServicePostApiImportImportLineitem } from "../../../../openapi/queries";
import { BaseModal } from "../BaseModal/BaseModal";
import { LabeledInput } from "../LabeledInput/LabeledInput";

import { JSX } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/Add";
import { Button, Typography } from "@mui/material";

export const BtnImportConfigId = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [lineItemId, setLineItemId] = useState<string>("");
  const [isValidPattern, setIsValidPattern] = useState<boolean>(true);
  const { t } = useTranslation();
  const { mutate: mutateImportLineItem } =
    useImportServicePostApiImportImportLineitem();

  const handleOpenModal = (): void => {
    setIsModalOpen(true);
  };
  const handleCloseModal = (): void => {
    setIsModalOpen(false);
  };

  const handleChange = (val: string): void => {
    setIsValidPattern(true);

    const cleanedVal = val.trim();
    const regexGUIDpattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    if (!regexGUIDpattern.test(cleanedVal)) {
      setIsValidPattern(false);
    }

    setLineItemId(cleanedVal);
  };

  const handleSubmit = async (): Promise<void> => {
    await mutateImportLineItem(
      {
        requestBody: { lineItemId },
      },
      {
        onSuccess: (result) => {
          console.log("result", result);
        },
      },
    );
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={handleOpenModal}
        startIcon={<AddIcon />}
      >
        <Typography>{t("WEG:btn.importConfigId")}</Typography>
      </Button>
      <BaseModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={t("WEG:btn.importConfigId")}
      >
        <LabeledInput
          error={!isValidPattern && lineItemId.length > 0}
          label={t("WEG:label.configId")}
          value={lineItemId}
          handleChange={(val) => handleChange(val)}
          helperText={
            !isValidPattern && lineItemId.length > 0
              ? t("WEG:error.invalidGUID")
              : ""
          }
          button={
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={!lineItemId.length || !isValidPattern}
            >
              {t("WEG:btn.import")}
            </Button>
          }
        />
      </BaseModal>
    </>
  );
};
