import { customPalette } from "../../../theme/theme";
import { TextFieldSimple } from "../../MuiWrappers/TextFieldSimple";

import { JSX } from "react";

import { Box, FormControl, SxProps, Typography } from "@mui/material";

interface ILabeledInput {
  fullWidth?: boolean;
  multiline?: boolean;
  label?: string | JSX.Element;
  sx?: SxProps;
  value: string | number;
  handleChange: (value: string) => void;
  button?: JSX.Element;
  maxLength?: number;
  rows?: number;
  error?: boolean;
  helperText?: string;
}
export const LabeledInput = ({
  label,
  sx,
  handleChange,
  value,
  button,
  fullWidth,
  multiline,
  rows,
  maxLength,
  error,
  helperText,
}: ILabeledInput): JSX.Element => {
  const handleInputChange = (value: string | number): void => {
    let cleanedValue = value;
    if (
      typeof cleanedValue === "string" &&
      maxLength &&
      cleanedValue.length > maxLength
    ) {
      cleanedValue = cleanedValue.substring(0, maxLength);
    } else if (typeof cleanedValue === "number") {
      cleanedValue = cleanedValue.toString();
    }
    handleChange(cleanedValue);
  };

  return (
    <Box sx={sx}>
      <Box display="flex" alignItems="baseline" gap={1}>
        <Typography variant="subtitle2" color="textSecondary">
          {label}
        </Typography>
        {maxLength && typeof value === "string" ? (
          <Typography variant="caption" color={customPalette.grey[500]}>
            ({value.length}/{maxLength})
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      <Box display="flex" gap={2}>
        <FormControl fullWidth={fullWidth}>
          <TextFieldSimple
            helperText={helperText}
            error={error}
            rows={rows}
            value={value}
            multiline={multiline}
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
          />
        </FormControl>
        {button ? button : <></>}
      </Box>
    </Box>
  );
};
