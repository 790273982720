//import { NonCatalogProductSelection } from "../components/NonCatalogProductSelection/NonCatalogProductSelection";
import { JSX } from "react";

import { useLineItemsServiceGetByIdLineItems } from "@4pace-cpq/core";
import { useSearch } from "@tanstack/react-router";

import { Configurator } from "./Configurator";
import { ConfigurationPreselector } from "./preselector/ConfigurationPreselector";

export default function ConfiguratorContainer(): JSX.Element {
  const { cfgObjectId, lineItemId } = useSearch({
    from: "/configurator",
  });

  const { data: lineItem } = useLineItemsServiceGetByIdLineItems(
    { id: lineItemId, includes: "Configuration" },
    undefined,
    {
      enabled: !!lineItemId,
    },
  );

  if (lineItemId) {
    //if has a lineItemId, then it is a loaded configuration
    try {
      const parsedState = JSON.parse(lineItem?.configuration?.state || "");

      if (parsedState.Configuration?.Id) {
        return (
          <Configurator
            cfgObjectId={parsedState.Configuration.Id}
            state={lineItem?.configuration?.state}
            //productSelection={productSelection}
          />
        );
      }
    } catch (e) {
      console.error("Couldn't parse the cfgState", { lineItem });
    }
  }

  if (cfgObjectId) {
    //has no lineItem and a product id, means it is not in a lineItem yet, so a new configuration is started
    return (
      <Configurator
        cfgObjectId={cfgObjectId}
        //productSelection={productSelection}
      />
    );
  }

  //this should be unreachable
  return <ConfigurationPreselector />;
}
