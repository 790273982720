//import { useEasyServicePostApiEasyAskForQuotationEmail } from "../../../../openapi/queries";
import { BaseModal } from "../BaseModal/BaseModal";
import { LabeledInput } from "../LabeledInput/LabeledInput";
import i18next from "i18next";

import { JSX } from "react";
import { Suspense, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MailIcon from "@mui/icons-material/Mail";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import { QuoteFormSelect } from "./QuoteFormSelect";

interface IFormLocationItem {
  value: string;
  label: string;
}

interface ICountry {
  isocode: string;
}
interface IRegion {
  isocode: string;
}
interface IAddress {
  country: ICountry;
  email: string;
  defaultAddress: boolean;
  id: string;
  line1: string;
  phone: string;
  postalCode?: string;
  region?: IRegion;
}

interface IGeoPoint {
  latitude: number;
  longitude: number;
}
interface IPointOfService {
  label?: string;
  address: IAddress;
  displayName: string;
  geoPoint: IGeoPoint;
  name: string;
}

export const BtnAskForQuote = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  //const { mutate: mutateServiceQuotationMail, data } = useEasyServicePostApiEasyAskForQuotationEmail();
  const { t } = useTranslation();
  const [formUserName, setFormUserName] = useState<string>("");
  const [formUserSurname, setFormUserSurname] = useState<string>("");
  const [formUserStreet, setFormUserStreet] = useState<string>("");
  const [formUserStreetNr, setFormUserStreetNr] = useState<string>("");
  const [formUserZipCode, setFormUserZipCode] = useState<string>("");
  const [formUserCity, setFormUserCity] = useState<string>("");
  const [formUserEmail, setFormUserEmail] = useState<string>("");
  const [formUserPhoneNr, setFormUserPhoneNr] = useState<string>("");
  const [formUserComment, setFormUserComment] = useState<string>("");
  const [formCountries, setFormCountries] = useState<IFormLocationItem[]>([]);
  const [formRegions, setFormRegions] = useState<IFormLocationItem[]>([]);
  const [formCities, setFormCities] = useState<IFormLocationItem[]>([]);
  const [currentFormCountryId, setCurrentFormCountryId] = useState<string>("");
  const [currentFormRegionId, setCurrentFormRegionId] = useState<string>("");
  const [currentFormCityId, setCurrentFormCityId] = useState<string>("");
  const [pointsOfService, setPointsOfService] = useState<IPointOfService[]>([]);
  const [currentPointsOfService, setCurrentPointsOfService] = useState<
    string[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorFetchCountries, setErrorFetchCountries] =
    useState<boolean>(false);
  const [errorFetchRegions, setErrorFetchRegions] = useState<boolean>(false);
  const [errorFetchCities, setErrorFetchCities] = useState<boolean>(false);
  const [errorFetchPointsOfService, setErrorFetchPointsOfService] =
    useState<boolean>(false);

  const shortenedLanguage =
    i18next.language === "cimode" ? "en" : i18next.language.split("-")[0];

  const handleOpenModal = (): void => {
    setIsModalOpen(true);
  };
  const handleCloseModal = (): void => {
    setIsModalOpen(false);
  };

  const fetchCountries = useCallback(async () => {
    setIsLoading(true);
    setErrorFetchCountries(false);
    setFormRegions([]);
    setCurrentFormRegionId("");
    setFormCities([]);
    setCurrentFormCityId("");
    setPointsOfService([]);
    setCurrentPointsOfService([]);
    let response;
    try {
      response = await fetch("/api/Easy/AskForQuotationEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ language: shortenedLanguage }),
      });
      const data = await response.json();
      setFormCountries(data.countries);
    } catch (error) {
      setErrorFetchCountries(true);
      console.warn(error);
    }
    setIsLoading(false);
  }, [shortenedLanguage]);

  const fetchRegions = useCallback(async () => {
    setIsLoading(true);
    setErrorFetchRegions(false);
    setFormRegions([]);
    setCurrentFormRegionId("");
    setFormCities([]);
    setCurrentFormCityId("");
    setPointsOfService([]);
    setCurrentPointsOfService([]);
    let response;
    try {
      response = await fetch("/api/Easy/AskForQuotationEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          language: shortenedLanguage,
          country: currentFormCountryId,
        }),
      });
      const data = await response.json();
      const formattedRegions = data.regions.map((region: IFormLocationItem) => {
        return {
          value: region.value,
          label: region.label + " (" + region.value + ")",
        };
      });
      setFormRegions(formattedRegions);
    } catch (error) {
      setErrorFetchRegions(true);
      console.warn(error);
    }
    setIsLoading(false);
  }, [shortenedLanguage, currentFormCountryId]);

  const fetchCities = useCallback(async () => {
    setIsLoading(true);
    setErrorFetchCities(false);
    setFormCities([]);
    setCurrentFormCityId("");
    setPointsOfService([]);
    setCurrentPointsOfService([]);
    let response;
    try {
      response = await fetch("/api/Easy/AskForQuotationEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          language: shortenedLanguage,
          country: currentFormCountryId,
          region: currentFormRegionId,
        }),
      });
      const data = await response.json();
      setFormCities(data.towns);
    } catch (error) {
      setErrorFetchCities(true);
      console.warn(error);
    }
    setIsLoading(false);
  }, [shortenedLanguage, currentFormCountryId, currentFormRegionId]);

  const fetchPointsOfService = useCallback(async () => {
    setIsLoading(true);
    setErrorFetchPointsOfService(false);
    setPointsOfService([]);
    setCurrentPointsOfService([]);

    const requestBody = {
      language: shortenedLanguage,
      country: currentFormCountryId,
      region: currentFormRegionId,
      town: currentFormCityId,
    };

    let response;
    try {
      response = await fetch("/api/Easy/AskForQuotationEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      if (data.pointsOfService && data.pointsOfService.length > 0) {
        setPointsOfService(data.pointsOfService);
        setCurrentPointsOfService([data.pointsOfService[0].address.id]);
      }
    } catch (error) {
      setErrorFetchPointsOfService(true);
      console.warn(error);
    }
    setIsLoading(false);
  }, [
    shortenedLanguage,
    currentFormCountryId,
    currentFormRegionId,
    currentFormCityId,
  ]);

  //eslint-disable-next-line
  /*const fetchCountries2 = useCallback(async () => {
    const response = await mutateServiceQuotationMail(
      {
        requestBody: { language: "en" },
      },
      {
        onSuccess: (result) => {
          console.log("result", result);
          console.log("data2", { data });
        },
      },
    );
    console.log("fetchCountries2", response);
  }, [mutateServiceQuotationMail, data]);*/

  const handleChangeCurrentPOS = (id: string): void => {
    let newPOSArr = [...currentPointsOfService];
    if (newPOSArr.includes(id)) {
      newPOSArr = currentPointsOfService.filter((v) => v !== id);
    } else {
      newPOSArr.push(id);
    }
    setCurrentPointsOfService(newPOSArr);
  };

  const handleSend = async (): Promise<void> => {
    setIsLoading(true);

    console.log("currentPointsOfService", currentPointsOfService);
    console.log("pointsOfService", pointsOfService);

    const requestBody = {
      eMail: formUserEmail,
      userName: formUserName + " " + formUserSurname,
      address: {
        street: formUserStreet,
        streetNr: formUserStreetNr,
        zipCode: formUserZipCode,
        city: formUserCity,
      },
      phoneNumber: formUserPhoneNr,
      comment: formUserComment,
      emails: currentPointsOfService,
      model: {
        language: shortenedLanguage,
        country: currentFormCountryId,
        region: currentFormRegionId,
        town: currentFormCityId,
      },
    };

    let response;
    try {
      response = await fetch("/api/AskForQuotation/SendAskForQuotationEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      if (data.pointsOfService && data.pointsOfService.length > 0) {
        setPointsOfService(data.pointsOfService);
        setCurrentPointsOfService([data.pointsOfService[0].address.id]);
      }
    } catch (error) {
      setErrorFetchPointsOfService(true);
      console.warn(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    //fetchCountries2();
    fetchCountries();
  }, [fetchCountries]);

  useEffect(() => {
    if (currentFormCountryId === "BR") {
      fetchRegions();
    }
  }, [fetchRegions, currentFormCountryId]);

  useEffect(() => {
    if (currentFormRegionId) {
      fetchCities();
    }
  }, [fetchCities, currentFormRegionId]);

  useEffect(() => {
    if (currentFormCountryId && currentFormRegionId && currentFormCityId) {
      fetchPointsOfService();
    } else if (currentFormCountryId && currentFormCountryId !== "BR") {
      fetchPointsOfService();
    }
  }, [
    fetchPointsOfService,
    currentFormCountryId,
    currentFormRegionId,
    currentFormCityId,
  ]);

  return (
    <>
      <Suspense>
        <Button variant="contained" size="small" onClick={handleOpenModal}>
          {t("WEG:btn.askForQuote")}
        </Button>
        <BaseModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          title={t("WEG:btn.askForQuote")}
          customSx={{
            minWidth: "30em",
            width: "25%",
            overflow: "auto",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "10",
                background: "rgba(0,0,0,.25)",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <></>
          )}
          <Box>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid size={12}>
                <LabeledInput
                  fullWidth={true}
                  label={t("WEG:label.form.email")}
                  value={formUserEmail}
                  handleChange={(val) => setFormUserEmail(val)}
                />
              </Grid>
              <Grid size={12}>
                <LabeledInput
                  fullWidth={true}
                  label={t("WEG:label.form.phone")}
                  value={formUserPhoneNr}
                  handleChange={(val) => setFormUserPhoneNr(val)}
                />
              </Grid>
              <Grid size={6}>
                <LabeledInput
                  fullWidth={true}
                  label={t("WEG:label.form.name")}
                  value={formUserName}
                  handleChange={(val) => setFormUserName(val)}
                />
              </Grid>
              <Grid size={6}>
                <LabeledInput
                  fullWidth={true}
                  label={t("WEG:label.form.surname")}
                  value={formUserSurname}
                  handleChange={(val) => setFormUserSurname(val)}
                />
              </Grid>
              <Grid size={10}>
                <LabeledInput
                  fullWidth={true}
                  label={t("WEG:label.form.street")}
                  value={formUserStreet}
                  handleChange={(val) => setFormUserStreet(val)}
                />
              </Grid>
              <Grid size={2}>
                <LabeledInput
                  fullWidth={true}
                  label={t("WEG:label.form.streetNr")}
                  value={formUserStreetNr}
                  handleChange={(val) => setFormUserStreetNr(val)}
                />
              </Grid>
              <Grid size={4}>
                <LabeledInput
                  fullWidth={true}
                  label={t("WEG:label.form.zipCode")}
                  value={formUserZipCode}
                  handleChange={(val) => setFormUserZipCode(val)}
                />
              </Grid>
              <Grid size={8}>
                <LabeledInput
                  fullWidth={true}
                  label={t("WEG:label.form.city")}
                  value={formUserCity}
                  handleChange={(val) => setFormUserCity(val)}
                />
              </Grid>

              <Grid size={12}>
                <LabeledInput
                  fullWidth={true}
                  multiline={true}
                  rows={3}
                  label={t("WEG:label.form.formUserComment")}
                  maxLength={1000}
                  value={formUserComment}
                  handleChange={(val) => setFormUserComment(val)}
                />
              </Grid>
              <Grid size={12}>
                <QuoteFormSelect
                  currentVal={currentFormCountryId}
                  fetchData={fetchCountries}
                  hasError={errorFetchCountries}
                  label={t("WEG:label.form.formPurchasePlace")}
                  selectOptions={formCountries}
                  setter={setCurrentFormCountryId}
                />
              </Grid>
              {currentFormCountryId === "BR" ? (
                <>
                  <Grid size={6}>
                    <QuoteFormSelect
                      currentVal={currentFormRegionId}
                      fetchData={fetchRegions}
                      hasError={errorFetchRegions}
                      label={t("WEG:label.form.formRegion")}
                      selectOptions={formRegions}
                      setter={setCurrentFormRegionId}
                    />
                  </Grid>
                  <Grid size={6}>
                    <QuoteFormSelect
                      currentVal={currentFormCityId}
                      fetchData={fetchCities}
                      hasError={errorFetchCities}
                      label={t("WEG:label.form.formCity")}
                      selectOptions={formCities}
                      setter={setCurrentFormCityId}
                      disabled={!currentFormRegionId}
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>

            <Box display="flex" justifyContent="space-between" gap={2}>
              {/*<LabeledInput
              label={t("WEG:label.form.setFormRegion")}
              value={formRegion}
              handleChange={(val) => setFormRegion(val)}
            />*/}
            </Box>
            {errorFetchPointsOfService ? (
              <Box display="flex" justifyContent="center">
                <IconButton aria-label="reload" onClick={fetchPointsOfService}>
                  <RefreshIcon fontSize="inherit" />
                </IconButton>
              </Box>
            ) : (
              <></>
            )}
            {pointsOfService && pointsOfService.length > 0 ? (
              <Box sx={{ maxHeight: "15em", overflow: "auto" }}>
                <FormGroup>
                  {pointsOfService.map((pos) => {
                    const {
                      displayName,
                      address: { country, email },
                    } = pos;

                    return (
                      <FormControlLabel
                        key={"pos-" + pos.address.id}
                        control={
                          <Checkbox
                            checked={currentPointsOfService.includes(
                              pos.address.id,
                            )}
                            onChange={() => {
                              handleChangeCurrentPOS(pos.address.id);
                            }}
                          />
                        }
                        label={
                          (displayName ? displayName : country.isocode) +
                          " - " +
                          email
                        }
                      />
                    );
                  })}
                </FormGroup>
              </Box>
            ) : !isLoading &&
              !errorFetchPointsOfService &&
              currentFormCountryId &&
              (currentFormCountryId !== "BR" || currentFormCityId) ? (
              <Typography>{t("WEG:error.noPOSfound")}</Typography>
            ) : (
              <></>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" pt={4}>
            <Button
              variant="contained"
              disabled={currentPointsOfService.length < 1}
              endIcon={<MailIcon />}
              onClick={handleSend}
            >
              {t("WEG:btn.sendRequest")}
            </Button>
          </Box>
        </BaseModal>
      </Suspense>
    </>
  );
};
