import { createRoot } from "react-dom/client";

import { CpqQueryClientProvider } from "@4pace-cpq/core";

import { App } from "./App";
import "./index.css";
import "./localization/i18n";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <CpqQueryClientProvider>
      <App />
    </CpqQueryClientProvider>,
  );
} else {
  console.error("Root element not found");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
