import { JSX } from "react";

import Grid from "@mui/material/Grid2";
import { SubconfigurationNavigationItem } from "@plansoft-configuration-renderer/components";

type SubconfigurationItemLabelProps = {
  item: SubconfigurationNavigationItem;
  showCompleteIndicator?: boolean;
};

export function CustomSubconfigurationItemLabel(
  props: SubconfigurationItemLabelProps,
): JSX.Element {
  const { item, showCompleteIndicator } = props;

  if (!showCompleteIndicator) {
    return <>{item.label}</>;
  }

  if (item.custom) {
    return (
      <Grid
        container
        spacing={1}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span>c-incomplete</span>
      </Grid>
    );
  }
  return <>incomplete</>;
}
