import { useQuoteApiServiceGetApiQuoteApiGetQuoteId } from "../../../../openapi/queries";

import { JSX, useCallback, useEffect, useState } from "react";

import { Box, CircularProgress, Container } from "@mui/material";
import { useNavigate, useSearch } from "@tanstack/react-router";

export const CustomOpportunityRouteComponent = (): JSX.Element => {
  const { data: quoteId } = useQuoteApiServiceGetApiQuoteApiGetQuoteId();
  const [isLoadingWebWebsite, setIsLoadingWebWebsite] = useState<boolean>(true);
  const navigate = useNavigate();
  const {
    origin,
    purchaseLocation,
    customer,
    productCategorie,
    intermediateToken,
  } = useSearch({ strict: false });

  const handlePostSessionFromWegWebsite =
    useCallback(async (): Promise<void> => {
      setIsLoadingWebWebsite(true);
      await fetch("/api/Easy/SessionFromWegWebsite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          origin,
          purchaseLocation,
          customer,
          productCategorie,
          intermediateToken,
        }),
      }).then(() => {
        setIsLoadingWebWebsite(false);
      });
    }, [
      origin,
      purchaseLocation,
      customer,
      productCategorie,
      intermediateToken,
    ]);

  useEffect(() => {
    if (origin) {
      handlePostSessionFromWegWebsite();
    }
  }, [handlePostSessionFromWegWebsite, origin]);

  if (quoteId && !origin) {
    navigate({
      to: "/cart/view",
      //to: "/cart/$quoteId",
      //params: { quoteId: quoteId },
    });
  }

  return (
    <Container>
      <Box my={4}>
        {isLoadingWebWebsite || !quoteId ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={4}
          >
            <CircularProgress />
          </Box>
        ) : (
          <></>
        )}
        <Box display="flex" flexDirection="column" gap={1}>
          <code>quoteId: {quoteId}</code>
          {origin ? (
            <>
              <code>origin: {origin}</code>
              <code>purchaseLocation: {purchaseLocation}</code>
              <code>customer: {customer}</code>
              <code>productCategory: {productCategorie}</code>
              <code>intermediateToken: {intermediateToken}</code>
            </>
          ) : (
            <></>
          )}
        </Box>

        {/*<StandardComponents.OpportunityRouteComponent />*/}
      </Box>
    </Container>
  );
};
