export async function fetchCfgObjectIds(): Promise<string[]> {
  try {
    const response = await fetch(
      "/preview/api/3pq-cs/v2/configuration/configurators",
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.map((item: { id: string }) => item.id);
  } catch (error) {
    console.error("Failed to fetch configuration objects:", error);
    return [];
  }
}
