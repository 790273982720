import { JSX } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Link, Skeleton } from "@mui/material";

import { customPalette } from "./../../theme/theme";
import { INavItem } from "./AppHeaderNav";

export function AppHeaderNavItem({
  navItem,
  index,
  active,
}: {
  navItem: INavItem;
  index: number;
  active: boolean;
}): JSX.Element {
  const { label, subItems, href, isDummy } = navItem;
  if (isDummy) {
    return <></>;
  }
  return (
    <Box
      display="flex"
      alignItems="center"
      mr={1}
      color={active ? customPalette.primary.dark : customPalette.primary.main}
    >
      {index === 0 ? null : (
        <Box
          height="1.1em"
          mr={1}
          sx={{
            borderRightStyle: "solid",
            borderRightWidth: active ? "2px" : "1px",
            borderColor: active
              ? customPalette.primary.dark
              : customPalette.secondary.main,
          }}
        />
      )}
      {isDummy ? (
        <Skeleton width="6.18em" />
      ) : (
        <Link
          href={href}
          underline="none"
          color={
            active ? customPalette.primary.dark : customPalette.primary.main
          }
        >
          {label}
        </Link>
      )}
      {subItems.length ? <ExpandMoreIcon /> : null}
    </Box>
  );
}
