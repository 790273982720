import AddToQuoteDialog from "../../../../components/AddToQuoteDialog/AddToQuoteDialog";
import { AddToQuoteButton } from "../AddToQuoteButton/AddToQuoteButton";

import { JSX } from "react";
import { useTranslation } from "react-i18next";

import { Footer } from "@4pace-cpq/core";
//import { CreateTemplateButton } from "../CreateTemplate/CreateTemplateButton";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useCsConfigurationApi } from "@plansoft-configuration-renderer/framework";
import { GatewayDest } from "@plansoft-configuration-renderer/gateway";
import { useNavigate, useSearch } from "@tanstack/react-router";

import { SaveConfigurationButton } from "./SaveConfigurationButton";

export function ConfigurationControls(): JSX.Element {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { quoteId, configurationId, itemGroupId } = useSearch({
    strict: false,
  });

  const { resetCurrentCfgObject } = useCsConfigurationApi();
  console.log("ConfigurationControls");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {quoteId ? (
        <Button
          variant="text"
          color="primary"
          startIcon={<ArrowLeftIcon />}
          onClick={() =>
            navigate({
              to: "/cart/$quoteId",
              params: { quoteId },
            })
          }
        >
          {t("WEG:buttons.backToCart")}
        </Button>
      ) : (
        <Button
          variant="text"
          color="primary"
          startIcon={<ArrowLeftIcon />}
          onClick={() =>
            navigate({
              to: "/",
            })
          }
        >
          {t("WEG:buttons.backToRoot")}
        </Button>
      )}

      <Box display="flex" gap={2}>
        {configurationId ? (
          <SaveConfigurationButton configurationId={configurationId} />
        ) : itemGroupId ? (
          <AddToQuoteButton autoAdd={true} itemGroupId={itemGroupId} />
        ) : (
          <AddToQuoteDialog />
        )}
        {/*<CreateTemplateButton />*/}
        <Button
          variant="text"
          color="secondary"
          startIcon={<RestartAltIcon />}
          onClick={resetCurrentCfgObject}
        >
          {t("WEG:buttons.resetConfiguration")}
        </Button>
      </Box>

      <Footer>
        <Box sx={{ background: "#fff" }}>
          <Container>
            <Box
              my={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" gap={1} justifyContent="flex-start">
                <GatewayDest name="configurator-footer-left" />
              </Box>
              <Box display="flex" gap={1} justifyContent="flex-end">
                {configurationId ? (
                  <SaveConfigurationButton configurationId={configurationId} />
                ) : (
                  <></>
                )}
                <GatewayDest name="configurator-footer-right" />
              </Box>
            </Box>
          </Container>
        </Box>
      </Footer>
    </Box>
  );
}
