import { useState } from "react";
import { JSX } from "react";
import { useTranslation } from "react-i18next";

import { Button, CircularProgress, Link } from "@mui/material";

export const BtnJumpToEasy = ({
  isLoggedIntoEasy,
}: {
  isLoggedIntoEasy: boolean;
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleClick = async (): Promise<boolean> => {
    setIsLoading(true);

    let response;
    try {
      response = await fetch("/api/Easy/JumpToEasy", {
        method: "GET",
      });
      console.log("response", response);
      const data = await response.json();
      console.log("data", data);
      if (typeof data?.url === "string") {
        window.location.assign(data.url);
      }
    } catch (error) {
      console.warn(error);
    }

    setIsLoading(false);

    return true;
  };

  return (
    <Button
      component={Link}
      href="/api/Easy/RedirectToEasy"
      variant="contained"
      size="small"
      onClick={handleClick}
      disabled={!isLoggedIntoEasy}
    >
      {isLoading ? (
        <CircularProgress size={"1em"} sx={{ mr: ".25em" }} />
      ) : null}
      {t("WEG:btn.loginToEasy")}
    </Button>
  );
};
