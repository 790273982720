import { useCallback, useEffect, useState } from "react";
import { JSX } from "react";

import {
  ComponentsOverride,
  CpqCore,
  OverridableComponentsKeys,
  StandardComponents,
} from "@4pace-cpq/core";

import { Providers } from "./Providers";
import { AppHeader } from "./components/AppHeader/AppHeader";
import { CustomCartIdRouteComponent } from "./components/CustomCartIdRouteComponent/CustomCartIdRouteComponent";
import ConfiguratorContainer from "./configurator/ConfiguratorContainer";
import { CustomOpportunityRouteComponent } from "./cpq/overrides/CustomOpportunityRouteComponent/CustomOpportunityRouteComponent";
import QuoteDetailsViewPageHeader from "./cpq/overrides/QuoteDetailsViewPageHeader/QuoteDetailsViewPageHeader";
import { prettyLogger } from "./prettyLogger";
import { customPalette } from "./theme/theme";

prettyLogger(
  `FE Version ${__CPQ_CORE_FE_VERSION__}`,
  customPalette.primary.main,
);

export const appLanguage = "en-US";

export function App(): JSX.Element {
  const [isLoggedIntoEasy, setIsLoggedIntoEasy] = useState<boolean>(false);
  const [isCheckingLogin, setIsCheckingLogin] = useState<boolean>(true);

  const fetchCountries = useCallback(async () => {
    setIsCheckingLogin(true);
    let response;
    try {
      response = await fetch("/api/Easy/CheckLoginState", {
        method: "GET",
      });
      if (response.status === 200) {
        setIsLoggedIntoEasy(true);
      } else {
        setIsLoggedIntoEasy(false);
      }
    } catch (error) {
      console.warn(error);
      setIsLoggedIntoEasy(false);
    }
    setIsCheckingLogin(false);
  }, []);
  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);
  if (isCheckingLogin) {
    return <></>;
  }

  const componentsOverride: ComponentsOverride = {
    [OverridableComponentsKeys.QuoteDetailsViewPageHeader]:
      QuoteDetailsViewPageHeader,
    [OverridableComponentsKeys.MainHeader]: () => (
      <AppHeader isLoggedIntoEasy={isLoggedIntoEasy} />
    ),
    [OverridableComponentsKeys.GoBackToOpportunitiesButton]: () => <></>,
    [OverridableComponentsKeys.ConfiguratorRouteComponent]: () => (
      <ConfiguratorContainer />
    ),
    [OverridableComponentsKeys.OpportunityRouteComponent]: () => (
      <CustomOpportunityRouteComponent />
    ),
    [OverridableComponentsKeys.QuotesRouteComponent]: () => (
      <div>
        <StandardComponents.QuotesRouteComponent />
      </div>
    ),
    [OverridableComponentsKeys.CartIdRouteComponent]: () => (
      <CustomCartIdRouteComponent isLoggedIntoEasy={isLoggedIntoEasy} />
    ),
  };

  return (
    <Providers>
      <CpqCore
        languageCode={appLanguage}
        showDebugTools
        componentsOverride={componentsOverride}
        /* basePath="/quotes/74e7a7c7-c3e9-4570-b6d1-fb263134e90c/catalog" */
      />
    </Providers>
  );
}
