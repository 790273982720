import { JSX, useState } from "react";
import { useTranslation } from "react-i18next";

import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Button } from "@mui/material";

interface IBtnConfigSearchProduct {
  sessionId: string;
  path: string;
}

export const BtnConfigSearchProduct = ({
  sessionId,
  path,
}: IBtnConfigSearchProduct): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<
    "init" | "default" | "error" | "success"
  >("init");

  const handleClick = async (): Promise<void> => {
    setStatus("default");
    setIsLoading(true);
    await fetch(
      `/preview/api/3pq-cs/v2/configuration/configurations/${sessionId}/userDefinedGetter/searchProduct`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          path: path || "/",
        }),
      },
    )
      .then((response) => {
        if (response.status === 200) {
          setStatus("success");
        } else {
          setStatus("error");
          console.warn(
            "Error in BtnConfigSearchProduct http status: " + response.status,
          );
        }
      })
      .catch((error) => {
        setStatus("error");
        console.warn(error);
      });
    setIsLoading(false);
  };

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color={
        status === "success"
          ? "success"
          : status === "error"
            ? "error"
            : "primary"
      }
      endIcon={<DoubleArrowIcon />}
      loading={isLoading}
      loadingPosition="end"
    >
      {t("WEG:config.btn.searchProduct")}
    </Button>
  );
};
