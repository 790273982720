import { CartViewFooter } from "../../cpq/overrides/CartViewFooter/CartViewFooter";
import { CartViewHeading } from "../../cpq/overrides/CartViewHeading/CartViewHeading";
import { CustomLineItems } from "../CustomLineItems/CustomLineItems";

import { JSX } from "react";

import { useQuotesServiceGetByIdQuotes } from "@4pace-cpq/core";
import { Box, Container } from "@mui/material";
import { useNavigate, useParams } from "@tanstack/react-router";

export function CustomCartIdRouteComponent({
  isLoggedIntoEasy,
}: {
  isLoggedIntoEasy: boolean;
}): JSX.Element | null {
  const { cartId } = useParams({ strict: false });
  const navigate = useNavigate();
  const { data: item, isLoading } = useQuotesServiceGetByIdQuotes({
    id: cartId,
    includes:
      "LineItems.Configuration" +
      ",LineItems.SubItems.Configuration" +
      ",LineItems.SubItems.Product.Translations.Language" +
      ",PropertySet.Properties.Translations.Language" +
      ",LineItems.PropertySet.Properties.Translations.Language" +
      ",LineItems.SubItems.PropertySet.Properties.Translations.Language" +
      ",LineItems.Product.Translations.Language",
    /* "PriceLists" +
      ",LineItems.Status" +
      ",LineItems.PriceLists" +
      ",LineItems.SubItems.PriceLists" +
      ",LineItems.Product.ProductType" +
      ",LineItems.Product.PriceSets.Prices" +
      ",LineItems.SubItems.Product.ProductType" +
      ",LineItems.SubItems.Company" +
      ",LineItems.SubItems.Product.PriceSets.Prices",*/
  });
  console.log("CustomCartIdRouteComponent item", item);

  if (!item && !isLoading) {
    navigate({
      to: "/",
    });
  }

  const firstItemGroup = item?.itemGroups?.[0];

  if (isLoading || !item || !firstItemGroup) {
    return null;
  }
  const lineItems = firstItemGroup?.lineItems;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flexGrow="1"
    >
      <Container>
        <CartViewHeading item={item} itemGroupId={firstItemGroup.id} />

        {lineItems?.length ? (
          <CustomLineItems
            customerId={item.customerId}
            quoteId={item.id}
            lineItems={lineItems}
            itemGroupId={firstItemGroup.id}
          />
        ) : (
          <Box p={4}>
            <span>There are no projects.</span>
          </Box>
        )}
      </Container>
      <CartViewFooter isLoggedIntoEasy={isLoggedIntoEasy} />
    </Box>
  );
}
