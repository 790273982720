import { JSX } from "react";
import { useTranslation } from "react-i18next";

import {
  VMItemGroups,
  VMLineItems,
  useCompaniesServiceGetAllCompanies,
  useConfigurationsServiceCreateConfigurations,
  useLineItemsServiceCreateLineItems,
  useStatus,
} from "@4pace-cpq/core";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import { useCsConfigurationApi } from "@plansoft-configuration-renderer/framework";
import { useNavigate } from "@tanstack/react-router";

export function AddToQuoteButton({
  itemGroupId,
  autoAdd,
}: {
  itemGroupId: VMItemGroups["id"];
  autoAdd?: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { saveConfiguration } = useCsConfigurationApi();

  const { mutate: createLineItem } = useLineItemsServiceCreateLineItems();
  const { mutate: createConfiguration } =
    useConfigurationsServiceCreateConfigurations();
  //todo remove company and status, when BE sets to optional
  const { data: companies } = useCompaniesServiceGetAllCompanies();
  const { status } = useStatus();

  function handleClick(): void {
    if (itemGroupId && companies && status) {
      createLineItem(
        {
          requestBody: {
            companyId: companies[0]?.id || "",
            statusId: status.default.id || "",
            itemGroupId,
          },
        },
        {
          onSuccess: async (lineItem) => {
            await handleCreateConfiguration(lineItem.id);
          },
          onError: (error) => {
            console.error("Error creating line item", error);
          },
        },
      );
    }
  }

  async function handleCreateConfiguration(
    lineItemId: VMLineItems["id"],
  ): Promise<void> {
    const { state } = await saveConfiguration();
    console.log("state", state);

    createConfiguration(
      {
        requestBody: {
          //todo remove if BE allows to remove
          identifier: "-",
          lineItemId,
          state,
        },
      },
      {
        onSuccess: (configuration) => {
          console.log("success");
          navigate({
            to: "/configurator",
            search: (prevSearch: object) => {
              return {
                ...prevSearch,
                configurationId: configuration.id,
                lineItemId,
              };
            },
          });
        },
        onError: (error) => {
          console.error("Error creating configuration", error);
        },
      },
    );
  }

  console.log("neu!", autoAdd);
  if (autoAdd) {
    console.log("autoAdd");

    //handleClick()
  }

  return (
    <Button
      variant="text"
      color="secondary"
      startIcon={<AddBoxIcon />}
      onClick={handleClick}
    >
      {t("WEG:buttons.addToQuote")}
    </Button>
  );
}
