// generated with @7nohe/openapi-react-query-codegen@1.6.2
import {
  AskForQuotationService,
  EasyService,
  ImportService,
  QuoteApiService,
  StatusApiService,
  TestService,
} from "../requests/services.gen";
import {
  ConvertTokenInput,
  VMAskForQuotation,
  VMAskForQuotationEmail,
  VMEasyConnectionParams,
  VMImportLineItem,
  VMInitiateSessionFromWebsite,
  VMQuotesPost,
  WegTestCallInput,
} from "../requests/types.gen";

import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import * as Common from "./common";

export const useEasyServiceGetApiEasyCheckLoginState = <
  TData = Common.EasyServiceGetApiEasyCheckLoginStateDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseEasyServiceGetApiEasyCheckLoginStateKeyFn(queryKey),
    queryFn: () => EasyService.getApiEasyCheckLoginState() as TData,
    ...options,
  });
export const useEasyServiceGetApiEasyRedirectToEasy = <
  TData = Common.EasyServiceGetApiEasyRedirectToEasyDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseEasyServiceGetApiEasyRedirectToEasyKeyFn(queryKey),
    queryFn: () => EasyService.getApiEasyRedirectToEasy() as TData,
    ...options,
  });
export const useQuoteApiServiceGetPreviewApi3PqCpqV1Quotes = <
  TData = Common.QuoteApiServiceGetPreviewApi3PqCpqV1QuotesDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey:
      Common.UseQuoteApiServiceGetPreviewApi3PqCpqV1QuotesKeyFn(queryKey),
    queryFn: () => QuoteApiService.getPreviewApi3PqCpqV1Quotes() as TData,
    ...options,
  });
export const useQuoteApiServiceGetPreviewApi3PqCpqV1QuotesById = <
  TData = Common.QuoteApiServiceGetPreviewApi3PqCpqV1QuotesByIdDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    id,
  }: {
    id: string;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseQuoteApiServiceGetPreviewApi3PqCpqV1QuotesByIdKeyFn(
      { id },
      queryKey,
    ),
    queryFn: () =>
      QuoteApiService.getPreviewApi3PqCpqV1QuotesById({ id }) as TData,
    ...options,
  });
export const useQuoteApiServiceGetApiQuoteApiGetQuoteId = <
  TData = Common.QuoteApiServiceGetApiQuoteApiGetQuoteIdDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseQuoteApiServiceGetApiQuoteApiGetQuoteIdKeyFn(queryKey),
    queryFn: () => QuoteApiService.getApiQuoteApiGetQuoteId() as TData,
    ...options,
  });
export const useStatusApiServiceGetPreviewApi3PqCpqV1StatusById = <
  TData = Common.StatusApiServiceGetPreviewApi3PqCpqV1StatusByIdDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  {
    filter,
    id,
    includes,
    order,
    size,
    sort,
    start,
  }: {
    filter?: string;
    id: string;
    includes?: string;
    order?: string;
    size?: number;
    sort?: string;
    start?: number;
  },
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseStatusApiServiceGetPreviewApi3PqCpqV1StatusByIdKeyFn(
      { filter, id, includes, order, size, sort, start },
      queryKey,
    ),
    queryFn: () =>
      StatusApiService.getPreviewApi3PqCpqV1StatusById({
        filter,
        id,
        includes,
        order,
        size,
        sort,
        start,
      }) as TData,
    ...options,
  });
export const useTestServiceGetApiTest = <
  TData = Common.TestServiceGetApiTestDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseTestServiceGetApiTestKeyFn(queryKey),
    queryFn: () => TestService.getApiTest() as TData,
    ...options,
  });
export const useTestServiceGetApiTestJwTtoken = <
  TData = Common.TestServiceGetApiTestJwTtokenDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseTestServiceGetApiTestJwTtokenKeyFn(queryKey),
    queryFn: () => TestService.getApiTestJwTtoken() as TData,
    ...options,
  });
export const useTestServiceGetApiTestName = <
  TData = Common.TestServiceGetApiTestNameDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseTestServiceGetApiTestNameKeyFn(queryKey),
    queryFn: () => TestService.getApiTestName() as TData,
    ...options,
  });
export const useTestServiceGetApiTestEasyToken = <
  TData = Common.TestServiceGetApiTestEasyTokenDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseTestServiceGetApiTestEasyTokenKeyFn(queryKey),
    queryFn: () => TestService.getApiTestEasyToken() as TData,
    ...options,
  });
export const useTestServiceGetApiTestJumpToEasy = <
  TData = Common.TestServiceGetApiTestJumpToEasyDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseTestServiceGetApiTestJumpToEasyKeyFn(queryKey),
    queryFn: () => TestService.getApiTestJumpToEasy() as TData,
    ...options,
  });
export const useTestServiceGetApiTestGetMockSession = <
  TData = Common.TestServiceGetApiTestGetMockSessionDefaultResponse,
  TError = unknown,
  TQueryKey extends Array<unknown> = unknown[],
>(
  queryKey?: TQueryKey,
  options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
) =>
  useQuery<TData, TError>({
    queryKey: Common.UseTestServiceGetApiTestGetMockSessionKeyFn(queryKey),
    queryFn: () => TestService.getApiTestGetMockSession() as TData,
    ...options,
  });
export const useAskForQuotationServicePostApiAskForQuotationSendAskForQuotationEmail =
  <
    TData = Common.AskForQuotationServicePostApiAskForQuotationSendAskForQuotationEmailMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          requestBody?: VMAskForQuotation;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        requestBody?: VMAskForQuotation;
      },
      TContext
    >({
      mutationFn: ({ requestBody }) =>
        AskForQuotationService.postApiAskForQuotationSendAskForQuotationEmail({
          requestBody,
        }) as unknown as Promise<TData>,
      ...options,
    });
export const useEasyServicePostApiEasySessionFromEasy = <
  TData = Common.EasyServicePostApiEasySessionFromEasyMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        requestBody?: VMEasyConnectionParams;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      requestBody?: VMEasyConnectionParams;
    },
    TContext
  >({
    mutationFn: ({ requestBody }) =>
      EasyService.postApiEasySessionFromEasy({
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useEasyServicePostApiEasySessionFromWegWebsite = <
  TData = Common.EasyServicePostApiEasySessionFromWegWebsiteMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        requestBody?: VMInitiateSessionFromWebsite;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      requestBody?: VMInitiateSessionFromWebsite;
    },
    TContext
  >({
    mutationFn: ({ requestBody }) =>
      EasyService.postApiEasySessionFromWegWebsite({
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useEasyServicePostApiEasyAskForQuotationEmail = <
  TData = Common.EasyServicePostApiEasyAskForQuotationEmailMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        requestBody?: VMAskForQuotationEmail;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      requestBody?: VMAskForQuotationEmail;
    },
    TContext
  >({
    mutationFn: ({ requestBody }) =>
      EasyService.postApiEasyAskForQuotationEmail({
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useImportServicePostApiImportImportLineitem = <
  TData = Common.ImportServicePostApiImportImportLineitemMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        requestBody?: VMImportLineItem;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      requestBody?: VMImportLineItem;
    },
    TContext
  >({
    mutationFn: ({ requestBody }) =>
      ImportService.postApiImportImportLineitem({
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useQuoteApiServicePostPreviewApi3PqCpqV1Quotes = <
  TData = Common.QuoteApiServicePostPreviewApi3PqCpqV1QuotesMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        requestBody?: VMQuotesPost;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      requestBody?: VMQuotesPost;
    },
    TContext
  >({
    mutationFn: ({ requestBody }) =>
      QuoteApiService.postPreviewApi3PqCpqV1Quotes({
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useTestServicePostApiTestConvertToSingleUseToken = <
  TData = Common.TestServicePostApiTestConvertToSingleUseTokenMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        requestBody?: ConvertTokenInput;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      requestBody?: ConvertTokenInput;
    },
    TContext
  >({
    mutationFn: ({ requestBody }) =>
      TestService.postApiTestConvertToSingleUseToken({
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useTestServicePostApiTestWegTestCall = <
  TData = Common.TestServicePostApiTestWegTestCallMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<
      TData,
      TError,
      {
        requestBody?: WegTestCallInput;
      },
      TContext
    >,
    "mutationFn"
  >,
) =>
  useMutation<
    TData,
    TError,
    {
      requestBody?: WegTestCallInput;
    },
    TContext
  >({
    mutationFn: ({ requestBody }) =>
      TestService.postApiTestWegTestCall({
        requestBody,
      }) as unknown as Promise<TData>,
    ...options,
  });
export const useTestServicePostApiTestMockEasySynchronization = <
  TData = Common.TestServicePostApiTestMockEasySynchronizationMutationResult,
  TError = unknown,
  TContext = unknown,
>(
  options?: Omit<
    UseMutationOptions<TData, TError, void, TContext>,
    "mutationFn"
  >,
) =>
  useMutation<TData, TError, void, TContext>({
    mutationFn: () =>
      TestService.postApiTestMockEasySynchronization() as unknown as Promise<TData>,
    ...options,
  });
