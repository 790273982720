// generated with @7nohe/openapi-react-query-codegen@1.6.2
import {
  AskForQuotationService,
  EasyService,
  ImportService,
  QuoteApiService,
  StatusApiService,
  TestService,
} from "../requests/services.gen";

import { UseQueryResult } from "@tanstack/react-query";

export type EasyServiceGetApiEasyCheckLoginStateDefaultResponse = Awaited<
  ReturnType<typeof EasyService.getApiEasyCheckLoginState>
>;
export type EasyServiceGetApiEasyCheckLoginStateQueryResult<
  TData = EasyServiceGetApiEasyCheckLoginStateDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useEasyServiceGetApiEasyCheckLoginStateKey =
  "EasyServiceGetApiEasyCheckLoginState";
export const UseEasyServiceGetApiEasyCheckLoginStateKeyFn = (
  queryKey?: Array<unknown>,
) => [useEasyServiceGetApiEasyCheckLoginStateKey, ...(queryKey ?? [])];
export type EasyServiceGetApiEasyRedirectToEasyDefaultResponse = Awaited<
  ReturnType<typeof EasyService.getApiEasyRedirectToEasy>
>;
export type EasyServiceGetApiEasyRedirectToEasyQueryResult<
  TData = EasyServiceGetApiEasyRedirectToEasyDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useEasyServiceGetApiEasyRedirectToEasyKey =
  "EasyServiceGetApiEasyRedirectToEasy";
export const UseEasyServiceGetApiEasyRedirectToEasyKeyFn = (
  queryKey?: Array<unknown>,
) => [useEasyServiceGetApiEasyRedirectToEasyKey, ...(queryKey ?? [])];
export type QuoteApiServiceGetPreviewApi3PqCpqV1QuotesDefaultResponse = Awaited<
  ReturnType<typeof QuoteApiService.getPreviewApi3PqCpqV1Quotes>
>;
export type QuoteApiServiceGetPreviewApi3PqCpqV1QuotesQueryResult<
  TData = QuoteApiServiceGetPreviewApi3PqCpqV1QuotesDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useQuoteApiServiceGetPreviewApi3PqCpqV1QuotesKey =
  "QuoteApiServiceGetPreviewApi3PqCpqV1Quotes";
export const UseQuoteApiServiceGetPreviewApi3PqCpqV1QuotesKeyFn = (
  queryKey?: Array<unknown>,
) => [useQuoteApiServiceGetPreviewApi3PqCpqV1QuotesKey, ...(queryKey ?? [])];
export type QuoteApiServiceGetPreviewApi3PqCpqV1QuotesByIdDefaultResponse =
  Awaited<ReturnType<typeof QuoteApiService.getPreviewApi3PqCpqV1QuotesById>>;
export type QuoteApiServiceGetPreviewApi3PqCpqV1QuotesByIdQueryResult<
  TData = QuoteApiServiceGetPreviewApi3PqCpqV1QuotesByIdDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useQuoteApiServiceGetPreviewApi3PqCpqV1QuotesByIdKey =
  "QuoteApiServiceGetPreviewApi3PqCpqV1QuotesById";
export const UseQuoteApiServiceGetPreviewApi3PqCpqV1QuotesByIdKeyFn = (
  {
    id,
  }: {
    id: string;
  },
  queryKey?: Array<unknown>,
) => [
  useQuoteApiServiceGetPreviewApi3PqCpqV1QuotesByIdKey,
  ...(queryKey ?? [{ id }]),
];
export type QuoteApiServiceGetApiQuoteApiGetQuoteIdDefaultResponse = Awaited<
  ReturnType<typeof QuoteApiService.getApiQuoteApiGetQuoteId>
>;
export type QuoteApiServiceGetApiQuoteApiGetQuoteIdQueryResult<
  TData = QuoteApiServiceGetApiQuoteApiGetQuoteIdDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useQuoteApiServiceGetApiQuoteApiGetQuoteIdKey =
  "QuoteApiServiceGetApiQuoteApiGetQuoteId";
export const UseQuoteApiServiceGetApiQuoteApiGetQuoteIdKeyFn = (
  queryKey?: Array<unknown>,
) => [useQuoteApiServiceGetApiQuoteApiGetQuoteIdKey, ...(queryKey ?? [])];
export type StatusApiServiceGetPreviewApi3PqCpqV1StatusByIdDefaultResponse =
  Awaited<ReturnType<typeof StatusApiService.getPreviewApi3PqCpqV1StatusById>>;
export type StatusApiServiceGetPreviewApi3PqCpqV1StatusByIdQueryResult<
  TData = StatusApiServiceGetPreviewApi3PqCpqV1StatusByIdDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useStatusApiServiceGetPreviewApi3PqCpqV1StatusByIdKey =
  "StatusApiServiceGetPreviewApi3PqCpqV1StatusById";
export const UseStatusApiServiceGetPreviewApi3PqCpqV1StatusByIdKeyFn = (
  {
    filter,
    id,
    includes,
    order,
    size,
    sort,
    start,
  }: {
    filter?: string;
    id: string;
    includes?: string;
    order?: string;
    size?: number;
    sort?: string;
    start?: number;
  },
  queryKey?: Array<unknown>,
) => [
  useStatusApiServiceGetPreviewApi3PqCpqV1StatusByIdKey,
  ...(queryKey ?? [{ filter, id, includes, order, size, sort, start }]),
];
export type TestServiceGetApiTestDefaultResponse = Awaited<
  ReturnType<typeof TestService.getApiTest>
>;
export type TestServiceGetApiTestQueryResult<
  TData = TestServiceGetApiTestDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useTestServiceGetApiTestKey = "TestServiceGetApiTest";
export const UseTestServiceGetApiTestKeyFn = (queryKey?: Array<unknown>) => [
  useTestServiceGetApiTestKey,
  ...(queryKey ?? []),
];
export type TestServiceGetApiTestJwTtokenDefaultResponse = Awaited<
  ReturnType<typeof TestService.getApiTestJwTtoken>
>;
export type TestServiceGetApiTestJwTtokenQueryResult<
  TData = TestServiceGetApiTestJwTtokenDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useTestServiceGetApiTestJwTtokenKey =
  "TestServiceGetApiTestJwTtoken";
export const UseTestServiceGetApiTestJwTtokenKeyFn = (
  queryKey?: Array<unknown>,
) => [useTestServiceGetApiTestJwTtokenKey, ...(queryKey ?? [])];
export type TestServiceGetApiTestNameDefaultResponse = Awaited<
  ReturnType<typeof TestService.getApiTestName>
>;
export type TestServiceGetApiTestNameQueryResult<
  TData = TestServiceGetApiTestNameDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useTestServiceGetApiTestNameKey = "TestServiceGetApiTestName";
export const UseTestServiceGetApiTestNameKeyFn = (
  queryKey?: Array<unknown>,
) => [useTestServiceGetApiTestNameKey, ...(queryKey ?? [])];
export type TestServiceGetApiTestEasyTokenDefaultResponse = Awaited<
  ReturnType<typeof TestService.getApiTestEasyToken>
>;
export type TestServiceGetApiTestEasyTokenQueryResult<
  TData = TestServiceGetApiTestEasyTokenDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useTestServiceGetApiTestEasyTokenKey =
  "TestServiceGetApiTestEasyToken";
export const UseTestServiceGetApiTestEasyTokenKeyFn = (
  queryKey?: Array<unknown>,
) => [useTestServiceGetApiTestEasyTokenKey, ...(queryKey ?? [])];
export type TestServiceGetApiTestJumpToEasyDefaultResponse = Awaited<
  ReturnType<typeof TestService.getApiTestJumpToEasy>
>;
export type TestServiceGetApiTestJumpToEasyQueryResult<
  TData = TestServiceGetApiTestJumpToEasyDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useTestServiceGetApiTestJumpToEasyKey =
  "TestServiceGetApiTestJumpToEasy";
export const UseTestServiceGetApiTestJumpToEasyKeyFn = (
  queryKey?: Array<unknown>,
) => [useTestServiceGetApiTestJumpToEasyKey, ...(queryKey ?? [])];
export type TestServiceGetApiTestGetMockSessionDefaultResponse = Awaited<
  ReturnType<typeof TestService.getApiTestGetMockSession>
>;
export type TestServiceGetApiTestGetMockSessionQueryResult<
  TData = TestServiceGetApiTestGetMockSessionDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useTestServiceGetApiTestGetMockSessionKey =
  "TestServiceGetApiTestGetMockSession";
export const UseTestServiceGetApiTestGetMockSessionKeyFn = (
  queryKey?: Array<unknown>,
) => [useTestServiceGetApiTestGetMockSessionKey, ...(queryKey ?? [])];
export type AskForQuotationServicePostApiAskForQuotationSendAskForQuotationEmailMutationResult =
  Awaited<
    ReturnType<
      typeof AskForQuotationService.postApiAskForQuotationSendAskForQuotationEmail
    >
  >;
export type EasyServicePostApiEasySessionFromEasyMutationResult = Awaited<
  ReturnType<typeof EasyService.postApiEasySessionFromEasy>
>;
export type EasyServicePostApiEasySessionFromWegWebsiteMutationResult = Awaited<
  ReturnType<typeof EasyService.postApiEasySessionFromWegWebsite>
>;
export type EasyServicePostApiEasyAskForQuotationEmailMutationResult = Awaited<
  ReturnType<typeof EasyService.postApiEasyAskForQuotationEmail>
>;
export type ImportServicePostApiImportImportLineitemMutationResult = Awaited<
  ReturnType<typeof ImportService.postApiImportImportLineitem>
>;
export type QuoteApiServicePostPreviewApi3PqCpqV1QuotesMutationResult = Awaited<
  ReturnType<typeof QuoteApiService.postPreviewApi3PqCpqV1Quotes>
>;
export type TestServicePostApiTestConvertToSingleUseTokenMutationResult =
  Awaited<ReturnType<typeof TestService.postApiTestConvertToSingleUseToken>>;
export type TestServicePostApiTestWegTestCallMutationResult = Awaited<
  ReturnType<typeof TestService.postApiTestWegTestCall>
>;
export type TestServicePostApiTestMockEasySynchronizationMutationResult =
  Awaited<ReturnType<typeof TestService.postApiTestMockEasySynchronization>>;
