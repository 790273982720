import { JSX } from "react";

import {
  ConfigurationTree,
  ConfigurationTreeProps,
} from "@plansoft-configuration-renderer/components";
// needs GatewayProvider and Gateway to work
import { Gateway } from "@plansoft-configuration-renderer/gateway";

function NavigationOutsideLayout(props: ConfigurationTreeProps): JSX.Element {
  console.log(props);
  return (
    <Gateway into="navigation">
      <ConfigurationTree {...props} />
    </Gateway>
  );
}

export default NavigationOutsideLayout as typeof ConfigurationTree;
