import { BtnConfigSearchProduct } from "../../../../../components/common/BtnConfigSearchProduct/BtnConfigSearchProduct";

import { JSX } from "react";

import { useSessionId } from "@plansoft-configuration-renderer/framework";
import { Gateway } from "@plansoft-configuration-renderer/gateway";

export const UtilsProfessionalSelection = (): JSX.Element => {
  const sessionId = useSessionId();

  return (
    <>
      <Gateway into="configurator-footer-right">
        <BtnConfigSearchProduct sessionId={sessionId} path="/XAS_SELECTION/0" />
      </Gateway>
    </>
  );
};
