import { CustomPageHeading } from "../../components/CustomPageHeading/CustomPageHeading";

import { JSX } from "react";
import { useTranslation } from "react-i18next";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Link, useNavigate } from "@tanstack/react-router";

import { PreselectorBtn } from "./PreselectorBtn";

export const ConfigurationPreselector = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box>
      <Container>
        <Box>
          <Button
            onClick={() => {
              navigate({
                to: "/",
              });
            }}
            variant="text"
          >
            <ChevronLeftIcon />
            {t("WEG:button.back")}
          </Button>
        </Box>
        <CustomPageHeading
          text={t("WEG:title.configurator.preselection")}
          //subtitle={item.name}
        />
        <Box mb={6}>
          <Typography variant="body1">
            {t("WEG:preselector.page1.text")}
          </Typography>
          <Link href="WEG:preselector.page1.link">
            {t("WEG:preselector.page1.linkText")}
          </Link>
        </Box>
        <Grid container spacing={2}>
          <Grid size={3}>
            <PreselectorBtn
              label={t("WEG:title.configurator.appCalc")}
              cfgObjectId="CFG_APPLICATION"
            />
          </Grid>
          <Grid size={3}>
            <PreselectorBtn
              label={t("WEG:title.configurator.professional")}
              cfgObjectId="CFG_PROFESSIONAL"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
