import { customPalette } from "../../theme/theme";

import { JSX } from "react";

import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useNavigate, useSearch } from "@tanstack/react-router";

interface IPreselectionBtn {
  label: string;
  cfgObjectId: string;
  imgSrc?: string;
}

export const PreselectorBtn = ({
  label,
  cfgObjectId,
}: IPreselectionBtn): JSX.Element => {
  const navigate = useNavigate();
  //eslint-disable-next-line
  const { itemGroupId } = useSearch({
    strict: false,
  });

  const handleNavigate = (): void => {
    navigate({
      to: "/configurator",
      search: (prev: Record<string, unknown>) => ({
        ...prev,
        cfgObjectId,
      }),
    });
  };

  const handleClick = (): void => {
    handleNavigate();
  };

  return (
    <>
      <Button
        onClick={() => {
          handleClick();
        }}
        sx={{
          width: "100%",
          position: "relative",
          padding: 0,
          border: "1px solid #BDBFC4",
          "&:hover": {
            borderColor: customPalette.primary.main,
          },
        }}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Box height="15em">
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </Box>
          <Typography
            textAlign="left"
            sx={{
              backgroundColor: customPalette.primary.main,
              color: "#fff",
              py: ".5em",
              px: "1em",
            }}
          >
            {label}
          </Typography>
        </Box>
      </Button>
    </>
  );
};
