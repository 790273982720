import { LabeledSelect } from "../LabeledSelect/LabeledSelect";

import { JSX } from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, IconButton, MenuItem } from "@mui/material";

interface ISelectOptions {
  value: string;
  label: string;
}
interface IQuoteFormSelect {
  currentVal: string;
  fetchData: () => void;
  hasError: boolean;
  label: string;
  selectOptions: ISelectOptions[];
  setter: (value: string) => void;
  disabled?: boolean;
}
export const QuoteFormSelect = ({
  currentVal,
  fetchData,
  hasError,
  label,
  selectOptions,
  setter,
  disabled,
}: IQuoteFormSelect): JSX.Element => {
  return (
    <Box display="flex" gap={1} alignItems="flex-end" flexGrow="1">
      {hasError ? (
        <IconButton aria-label="reload" onClick={fetchData}>
          <RefreshIcon />
        </IconButton>
      ) : (
        <></>
      )}
      <Box flexGrow="1">
        <LabeledSelect
          fullWidth={true}
          label={label}
          value={currentVal}
          handleChange={(val) => setter(val.toString())}
          disabled={disabled}
        >
          <MenuItem value={""}>-</MenuItem>
          {selectOptions.map((option) => {
            return (
              <MenuItem key={"region-" + option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </LabeledSelect>
      </Box>
    </Box>
  );
};
