import { JSX } from "react";

import { Box } from "@mui/material";

import BrandLogo from "./../../assets/weg-brand.svg?react";
import { customPalette } from "./../../theme/theme";
import { AppHeaderNav } from "./AppHeaderNav";
import { AppHeaderUtilities } from "./AppHeaderUtilities";

export function AppHeader({
  isLoggedIntoEasy,
}: {
  isLoggedIntoEasy: boolean;
}): JSX.Element {
  return (
    <Box
      pt={1}
      pb={1}
      pr={2}
      pl={2}
      boxShadow="0 0 2px 2px rgba(0,0,0,.125)"
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex">
        <Box
          display="flex"
          alignItems="center"
          sx={{
            mr: 3,
            ">svg path": {
              fill: customPalette.primary.dark,
            },
          }}
        >
          <BrandLogo />
        </Box>
        <Box display="flex" alignItems="center">
          <AppHeaderNav />
        </Box>
      </Box>
      <AppHeaderUtilities isLoggedIntoEasy={isLoggedIntoEasy} />
    </Box>
  );
}
