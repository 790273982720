export default {
  WEG: {
    "btn.askForQuote": "Ask for Quote",
    "btn.import": "import",
    "btn.importConfigId": "Import Config ID",
    "btn.loginToEasy": "Return to EASY",
    "btn.sendRequest": "Send Request",
    "button.back": "Back",
    "button.upload": "Upload",
    "buttons.addToQuote": "Add to item list",
    "buttons.backToCart": "To project list",
    "buttons.backToRoot": "To the homepage",
    "buttons.resetConfiguration": "Reset",
    "buttons.saveAsTemplate": "Save template",
    "buttons.SaveConfigurationButton": "Save Configuration",
    "buttons.showAllProjects": "Show all projects",
    "cart.table.header.configurationId": "Config ID",
    "cart.table.header.customerId": "Customer ID/ TAG Nr",
    "cart.table.header.item": "Item",
    "cart.table.header.notes": "Notes",
    "cart.table.header.quantity": "Quantity",
    "cart.table.header.type": "Type",
    "cart.table.header.syncSelect": "Sync",
    "cartViewHeader.button.title": "New Configuration",
    "dialog.addToQuote.title": "Please select an article list",
    "header.cart.title": "Project list",
    "label.configId": "Config ID",
    "label.form.email": "E-Mail",
    "label.form.name": "Name",
    "label.form.surname": "Surname",
    "label.form.street": "Street",
    "label.form.streetNr": "No.",
    "label.form.zipCode": "ZIP-Code",
    "label.form.city": "Town/City",
    "label.form.phone": "Phone",
    "label.form.formUserComment": "Comment",
    "label.form.formPurchasePlace": "Purchase Place",
    "label.form.formRegion": "Region",
    "label.form.formCity": "City",
    "error.noPOSfound": "No Points of Service found.",
    "error.invalidGUID": "Invalid GUID.",
    "latestProjects.table.header.batch": "Commission",
    "latestProjects.table.header.lastChange": "Last change",
    "latestProjects.table.header.orderNumber": "Order number",
    "latestProjects.table.header.projectName": "Project name",
    "latestProjects.table.header.projectNumber": "Project number",
    "latestProjects.table.title": "Most recent projects",
    "preselector.page1.link": "#",
    "preselector.page1.linkText": "Need help?",
    "preselector.page1.text": "Find your Product based on your needs.",
    "products.buttons.addToQuote": "Add to item list",
    "products.buttons.configure": "Configure",
    "title.configurator.appCalc": "Application Calculator",
    "title.configurator.preselection": "Productfinder",
    "title.configurator.professional": "Professional",
    "tooltip.copyToClipboard": "copy",
    "config.btn.searchProduct": "Search Product",
  },
  CPQ: {
    "button.createQuote": "test", ////todo -[ am ]-  fix
  },
};
