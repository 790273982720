import { useState } from "react";
import { JSX } from "react";
import { useTranslation } from "react-i18next";

import {
  CustomDialogActions,
  CustomDialogTitle,
  VMLineItems,
  useCompaniesServiceGetAllCompanies,
  useConfigurationsServiceCreateConfigurations,
  useLineItemsServiceCreateLineItems,
  useOpportunitiesServiceGetByIdOpportunities,
  useStatus,
} from "@4pace-cpq/core";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useCsConfigurationApi } from "@plansoft-configuration-renderer/framework";
import { useNavigate, useSearch } from "@tanstack/react-router";

export default function AddToQuoteDialog(): JSX.Element {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { opportunityId } = useSearch({
    strict: false,
  });

  const [quoteId, setQuoteId] = useState("");
  function handleClose(): void {
    setIsOpen(false);
  }

  const { data: opportunity } = useOpportunitiesServiceGetByIdOpportunities({
    id: opportunityId,
    includes: "Quotes",
  });

  const quotes = opportunity?.quotes || [];

  const { mutate: createLineItem } = useLineItemsServiceCreateLineItems();
  const { data: companies } = useCompaniesServiceGetAllCompanies();
  const { status } = useStatus();
  const { saveConfiguration } = useCsConfigurationApi();
  const { mutate: createConfiguration } =
    useConfigurationsServiceCreateConfigurations();

  function handleConfirm(): void {
    if (quoteId && companies && status) {
      createLineItem(
        {
          requestBody: {
            quoteId,
            companyId: companies[0]?.id || "",
            statusId: status.default.id || "",
          },
        },
        {
          onSuccess: async (lineItem) => {
            await handleCreateConfiguration(lineItem.id);
            setIsOpen(false);
            navigate({
              to: "/cart/$cartId",
              params: { cartId: quoteId },
            });
          },
          onError: (error) => {
            console.error("Error creating line item", error);
          },
        },
      );
    }
  }

  async function handleCreateConfiguration(
    lineItemId: VMLineItems["id"],
  ): Promise<void> {
    const { state } = await saveConfiguration();

    createConfiguration(
      {
        requestBody: {
          //todo remove if BE allows to remove
          identifier: "-",
          lineItemId,
          state,
        },
      },
      {
        onSuccess: (configuration) => {
          console.log("success");
          navigate({
            to: "/configurator",
            search: (prevSearch: object) => {
              return { ...prevSearch, configurationId: configuration.id };
            },
          });
        },
        onError: (error) => {
          console.error("Error creating configuration", error);
        },
      },
    );
  }

  return (
    <>
      <Button
        variant="text"
        color="secondary"
        startIcon={<AddBoxIcon />}
        onClick={() => setIsOpen(true)}
      >
        {t("WEG:buttons.addToQuote")}
      </Button>
      <Dialog onClose={handleClose} open={isOpen} fullWidth>
        <CustomDialogTitle
          title={t("WEG:dialog.addToQuote.title")}
          onClose={() => setIsOpen(false)}
        />
        <DialogContent>
          <Select
            fullWidth
            value={quoteId}
            onChange={(e) => setQuoteId(e.target.value)}
          >
            {quotes.map((quote, idx) => (
              <MenuItem key={idx} value={quote.id}>
                {quote.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <CustomDialogActions
          onCancel={() => setIsOpen(false)}
          onConfirm={handleConfirm}
        />
      </Dialog>
    </>
  );
}
