import { CustomSubconfigurationTabsNavigation } from "../CustomSubconfigurationTabsNavigation/CustomSubconfigurationTabsNavigation";

import type { JSX } from "react";
import { Fragment } from "react/jsx-runtime";

import {
  SubconfigurationNavigationButtons,
  SubconfigurationNavigationControl,
  SubconfigurationNavigationHorizontal,
  SubconfigurationNavigationOrientation,
  SubconfigurationNavigationSecondaryControl,
  SubconfigurationStepperNavigation,
} from "@plansoft-configuration-renderer/components";

import { CustomSubconfigurationNavigationVertical } from "./CustomSubconfigurationNavigationVertical";

/**
 * Components for the main navigation, i.e. tabs or stepper
 */
const NavigationComponents = {
  [SubconfigurationNavigationControl.Tabs]:
    CustomSubconfigurationTabsNavigation,
  [SubconfigurationNavigationControl.Stepper]:
    SubconfigurationStepperNavigation,
  [SubconfigurationNavigationControl.None]: (): JSX.Element => <></>,
};

/**
 * Components for the secondary navigation, i.e. reset, next, previous buttons
 */
const SecondaryNavigationComponents = {
  [SubconfigurationNavigationSecondaryControl.Buttons]:
    SubconfigurationNavigationButtons,
  [SubconfigurationNavigationSecondaryControl.None]: (): JSX.Element => (
    <Fragment />
  ),
};

/**
 * Components for alignment of the navigation and content
 */
const AlignmentComponents = {
  [SubconfigurationNavigationOrientation.Vertical]:
    CustomSubconfigurationNavigationVertical,
  [SubconfigurationNavigationOrientation.Horizontal]:
    SubconfigurationNavigationHorizontal,
};

/**
 * Components for the content area
 */
const ContentComponents = {
  FallbackUi: (): JSX.Element => <Fragment />,
  LoadingIndicator: (): JSX.Element => <Fragment />,
};

export const CustomSubconfigurationNavigationComponents = {
  NavigationComponents,
  SecondaryNavigationComponents,
  AlignmentComponents,
  ContentComponents,
};

export type CustomSubconfigurationNavigationComponents =
  typeof CustomSubconfigurationNavigationComponents;
