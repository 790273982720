import { useState } from "react";
import { JSX } from "react";

import BugReportIcon from "@mui/icons-material/BugReport";
import { Box, Drawer } from "@mui/material";
import {
  DebugOptionsSelector,
  DebugSpeedDial,
  DebugSpeedDialAction,
} from "@plansoft-configuration-renderer/components";

const isDevEnvironment =
  window.location.href.includes("stag") ||
  window.location.href.includes("dev") ||
  window.location.href.includes("localhost");

export function CustomDebugTools(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  if (!isDevEnvironment) {
    return <></>;
  }
  return (
    <>
      <Box position="fixed" right="20px" bottom="20px" zIndex={99999}>
        <DebugSpeedDial
          icon={<BugReportIcon />}
          defaultIsOpen={false}
          FabProps={{
            sx: {
              backgroundColor: "warning.main",
            },
          }}
        >
          <DebugSpeedDialAction
            icon={<BugReportIcon />}
            tooltipTitle={"Show debug dial"}
            onClick={() => setIsOpen(!isOpen)}
          />
        </DebugSpeedDial>
      </Box>
      <Drawer
        variant="temporary"
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DebugOptionsSelector />
      </Drawer>
    </>
  );
}
