import { JSX } from "react";

import {
  AttributeControlProps,
  Radios,
} from "@plansoft-configuration-renderer/components";

export function CustomRadio(props: AttributeControlProps): JSX.Element {
  const { children, ...restProps } = props;
  console.log("CustomRadio", restProps);
  return <Radios>{children}</Radios>;
}
