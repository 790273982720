import React from "react";
import { JSX } from "react";

import { Box, FormControl, Select, SxProps, Typography } from "@mui/material";

interface ILabeledSelect {
  fullWidth?: boolean;
  label?: string;
  sx?: SxProps;
  value: string | number;
  handleChange: (value: string | number) => void;
  button?: JSX.Element;
  children: React.ReactNode;
  disabled?: boolean;
}
export const LabeledSelect = ({
  label,
  sx,
  handleChange,
  value,
  button,
  fullWidth,
  children,
  disabled,
}: ILabeledSelect): JSX.Element => {
  return (
    <Box sx={sx}>
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
      <Box display="flex" gap={2}>
        <FormControl fullWidth={fullWidth}>
          <Select
            disabled={disabled}
            id={label + "-select"}
            value={value}
            size="small"
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          >
            {children}
          </Select>
        </FormControl>

        {button ? button : <></>}
      </Box>
    </Box>
  );
};
