import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";
import {
  cartStore,
  updateSelectedItems,
} from "../CustomCartIdRouteComponent/storeCart";
import { TextFieldSimple } from "../MuiWrappers/TextFieldSimple";

import { useState } from "react";
import { JSX } from "react";
import { useTranslation } from "react-i18next";

//import { isLineItem, isSubItem } from "../Table/helper/helper";

import {
  IconButtonLink,
  StandardComponents,
  VMItemGroups,
  VMLineItems,
  VMQuotes,
} from "@4pace-cpq/core";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Checkbox, TableCell, Tooltip, Typography } from "@mui/material";
import { useStore } from "@tanstack/react-store";

export interface ISyncSelect {
  configId: string;
  checked: boolean;
}
interface ICustomLineItem {
  item: VMLineItems;
  quoteId: VMQuotes["id"];
  itemGroupId: VMItemGroups["id"];
  idx: number;
}

export function CustomLineItem({
  item,
  quoteId,
  itemGroupId,
  idx,
}: ICustomLineItem): JSX.Element {
  const { t } = useTranslation();
  const [notes, setNotes] = useState<string>("");
  const [customerId, setCutsomerId] = useState<string>("");
  const checked = useStore(cartStore, (state) =>
    state.selectedItems.includes(item.id),
  );

  const search = {
    quoteId,
    itemGroupId,
    lineItemId: item.id,
    cfgObjectId: item.product?.cfgObjectIdentifier,
    configurationId: item.configurationId,
  };

  const handleSyncSelect = (): void => {
    updateSelectedItems(item.id);
  };

  return (
    <>
      <TableCell component="th" scope="row" align="right">
        <Checkbox checked={checked} onChange={handleSyncSelect} />
      </TableCell>
      <TableCell component="th" scope="row" align="right">
        {idx}
      </TableCell>
      <TableCell align="left">
        <Box display="flex" alignItems="center">
          <Tooltip title={item.id}>
            <Typography variant="body2" noWrap maxWidth={"10em"}>
              {item.id}
            </Typography>
          </Tooltip>
          <CopyToClipboard data={item.id?.toString()} />
        </Box>
      </TableCell>
      <TableCell align="left">-</TableCell>
      <TableCell
        align="left"
        sx={{ ">.MuiFormControl-root": { width: "100%" } }}
      >
        <StandardComponents.ConnectedQuantity
          item={item}
          value={item.quantity || 1}
        />
      </TableCell>
      <TableCell align="left" colSpan={2}>
        <TextFieldSimple
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          sx={{ width: "100%" }}
        />
      </TableCell>
      <TableCell align="left">
        <TextFieldSimple
          value={customerId}
          onChange={(e) => {
            setCutsomerId(e.target.value);
          }}
          sx={{ width: "10em" }}
        />
      </TableCell>
      <TableCell align="right">
        <Box display="flex">
          <Tooltip title={t("WEG:products.buttons.configure")}>
            <IconButtonLink to="/configurator" search={search}>
              <EditIcon />
            </IconButtonLink>
          </Tooltip>
          <StandardComponents.CloneItemIconButton item={item} />
          <StandardComponents.DeleteItemIconButton item={item} />
          {/*<OverridableComponents.ItemActionMenu item={item} />*/}
        </Box>
      </TableCell>

      {/*  <Box display="flex" gap={2} minWidth="500px">
       
           <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <BasicItemInfo item={item} />
        <StandardComponents.LineItemPlaceholder />
      </Box>
        <LabeledText
          label={t("CPQ:item.property.label.quantity")}
          text={
            <ConnectedNumericInput
              value={item.quantity || 0}
              propertyKey="quantity"
              item={item}
            />
          }
        />

        <StandardComponents.TotalList item={item} />
        <StandardComponents.TotalCost item={item} />
        <StandardComponents.TotalNet item={item} />
        <Box display="flex" alignItems="center" gap={1} ml="auto">
          {quoteId &&
            (isLineItem(item) || isSubItem(item)) &&
            item.productId &&
            item.product?.cfgObjectIdentifier && (
              <StandardComponents.IndicatorConfigurableProductInLineItem
                key="IndicatorConfigurableProductInLineItem"
                quoteId={quoteId}
                lineItemId={item.id}
                productId={item.productId}
                productTypeIdentifier={item.product?.productType?.identifier}
                configurationId={item.configurationId || undefined}
                isConfigurationComplete={item.isConfigurationComplete || false}
                cfgObjectId={item.product?.cfgObjectIdentifier}
              />
            )}
          </Box></Box></Box>*/}
    </>
  );
}
