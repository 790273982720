import type { JSX } from "react";

import Box from "@mui/material/Box";
import { SubconfigurationNavigationVerticalProps } from "@plansoft-configuration-renderer/components";

/**
 * overridden to lower the width of the navigation for the icon navigation
 * @param props
 * @constructor
 */
export function CustomSubconfigurationNavigationVertical(
  props: SubconfigurationNavigationVerticalProps,
): JSX.Element {
  return (
    <Box display="flex" sx={{ width: "auto" }}>
      <Box>{props.navigation}</Box>
      <Box sx={{ flex: 1 }}>{props.content}</Box>
    </Box>
  );
}
