import { JSX } from "react";
import { useTranslation } from "react-i18next";

import {
  VMConfigurations,
  useConfigurationsServiceGetByIdConfigurations,
  useConfigurationsServiceUpdateConfigurations,
} from "@4pace-cpq/core";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Button from "@mui/material/Button";
import { useCsConfigurationApi } from "@plansoft-configuration-renderer/framework";

export function SaveConfigurationButton({
  configurationId,
}: {
  configurationId: VMConfigurations["id"];
}): JSX.Element {
  const { t } = useTranslation();
  const { saveConfiguration } = useCsConfigurationApi();
  const { data: configuration } = useConfigurationsServiceGetByIdConfigurations(
    { id: configurationId },
  );

  const { mutate: updateConfiguration } =
    useConfigurationsServiceUpdateConfigurations();

  //todo remove company and status, when BE sets to optional

  async function handleClick(): Promise<void> {
    const { state } = await saveConfiguration();

    if (configurationId && configuration) {
      updateConfiguration(
        {
          id: configurationId,
          requestBody: {
            rowVersion: configuration.rowVersion,
            state,
            lineItemId: configuration.lineItemId,
          },
        },
        {
          onError: (error) => {
            console.error("Error creating configuration", error);
          },
        },
      );
    } else {
      console.error("Error creating configuration", { configurationId });
    }
  }

  return (
    <Button
      variant="text"
      color="secondary"
      startIcon={<AddBoxIcon />}
      onClick={handleClick}
      disabled={!configuration}
    >
      {t("WEG:buttons.SaveConfigurationButton")}
    </Button>
  );
}
