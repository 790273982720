import { JSX } from "react";

import Box from "@mui/material/Box";

import { Subtitle } from "./Subtitle";
import { Title } from "./Title";

export function CustomPageHeading({
  text,
  subtitle,
}: {
  text: string;
  subtitle?: string | undefined | null;
}): JSX.Element {
  return (
    <Box sx={{ marginTop: "1rem", mb: "2rem" }}>
      {text ? <Title text={text} /> : null}
      {subtitle ? <Subtitle text={subtitle} /> : null}
    </Box>
  );
}
