import { JSX } from "react";

import {
  AttributeComponents,
  AttributeStrategy,
  ConfigurationComponentType,
  ConfigurationComponents,
  ControlComponents,
  ControlComponentsMapping,
  LayoutComponentProvider,
  LayoutControlType,
} from "@plansoft-configuration-renderer/components";

import NavigationOutsideLayout from "./NavigationOutsideLayout";
import { CustomSubconfigurationNavigation } from "./components/CustomSubconfigurationNavigation/CustomSubconfigurationNavigation";
import { IndividualComponent } from "./components/Individual/IndividualComponent";
import { CustomRadio } from "./controls/CustomRadio";

// override control components for attributes
const controlComponents: ControlComponentsMapping = {
  ...ControlComponents, // the default components
  [LayoutControlType.Radios]: CustomRadio, // override radio control
};

// override configuration level components
const configurationComponents = {
  ...ConfigurationComponents,
  [ConfigurationComponentType.Individual]: IndividualComponent, // override individual control
  [ConfigurationComponentType.ConfigurationTree]: NavigationOutsideLayout,
  [ConfigurationComponentType.SubconfigurationNavigation]:
    CustomSubconfigurationNavigation,
};

// override attribute components
const attributeComponents = {
  ...AttributeComponents,
};

export function CustomRenderer(): JSX.Element {
  // see properties of LayoutComponentProvider for more information
  return (
    <LayoutComponentProvider
      configurationComponents={configurationComponents}
      attributeComponents={attributeComponents}
      controlComponents={controlComponents}
    >
      {/* Attribute strategy renders the components using the layout information */}
      <AttributeStrategy />
    </LayoutComponentProvider>
  );
}
