import { customPalette } from "../../theme/theme";

import { useState } from "react";
import { JSX } from "react";
import { useTranslation } from "react-i18next";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, Tooltip } from "@mui/material";

const CopyToClipboard = ({
  data,
}: {
  data: string | undefined;
}): JSX.Element => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  if (!data) {
    return <></>;
  }

  const handleCopy = async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(data);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Tooltip title={t("WEG:tooltip.copyToClipboard")}>
      <Button onClick={handleCopy} sx={{ minWidth: "30px" }}>
        <ContentCopyIcon
          sx={{
            height: "20px",
            color: copied
              ? customPalette.primary.main
              : customPalette.secondary.light,
          }}
        />
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboard;
