import { JSX } from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import { IndividualControlLayoutProps } from "@plansoft-configuration-renderer/components";

import { UtilsApplication } from "./variants/UtilsApplication";
import { UtilsProductView } from "./variants/UtilsProductView";
import { UtilsProfessionalSelection } from "./variants/UtilsProfessionalSelection";

export function IndividualComponent(
  props: IndividualControlLayoutProps,
): JSX.Element {
  const { variant = "variantFallback", custom = {} } = props;

  let componentBody;
  switch (variant) {
    case "utils_ProductView":
      componentBody = <UtilsProductView />;
      break;
    case "utils_ProfessionalSelection":
      componentBody = <UtilsProfessionalSelection />;
      break;
    case "utils_Professional":
      componentBody = <></>;
      break;
    case "utils_Application":
      componentBody = <UtilsApplication />;
      break;

    default:
      componentBody = (
        <Alert>
          <AlertTitle>{variant}</AlertTitle>
          <code>{JSON.stringify(custom, null, 2)}22</code>
        </Alert>
      );
      break;
  }

  return <Box my={2}>{componentBody}</Box>;
}
