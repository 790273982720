import { Store } from "@tanstack/react-store";

export const cartStore = new Store({
  selectedItems: [] as string[],
});

export const updateSelectedItems = (configId: string): void => {
  cartStore.setState((state) => {
    let newSelectedItems = [...state.selectedItems];
    if (newSelectedItems.indexOf(configId) >= 0) {
      newSelectedItems = newSelectedItems.filter((item) => item !== configId);
    } else {
      newSelectedItems.push(configId);
    }

    return {
      ...cartStore,
      selectedItems: newSelectedItems,
    };
  });
};
