import { customPalette } from "../../theme/theme";

import { ReactNode } from "react";
import { JSX } from "react";

import Typography from "@mui/material/Typography";

export function Subtitle({ text }: { text: ReactNode }): JSX.Element {
  return (
    <Typography
      variant="caption"
      sx={{
        color: customPalette.primary.main,
        letterSpacing: "0.46px",
        lineHeight: "1.375rem",
      }}
    >
      {text}
    </Typography>
  );
}
