import { JSX } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Modal,
  Paper,
  SxProps,
  Typography,
} from "@mui/material";

interface IBaseModal {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  customSx?: SxProps;
}
export const BaseModal = ({
  children,
  isOpen,
  onClose,
  title,
  customSx,
}: IBaseModal): JSX.Element => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Paper
        sx={{
          position: "relative",
          ...customSx,
        }}
      >
        <Box pt={1} pb={2} px={1}>
          <Box
            pb={1}
            pl={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2">{title ? title : ""}</Typography>
            <IconButton
              aria-label="delete"
              onClick={onClose}
              color="primary"
              size="large"
              sx={{
                p: 0,
                ">svg": {
                  height: "2em",
                  width: "2em",
                },
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box py={1} px={3}>
            {children}
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};
